// @flow

/**
 * determineViewInRoomBackgrounds
 *
 * @param {number} width
 * @param {number} height
 * @param {Array<Object>} config
 * @returns {Object}
 */
export function determineViewInRoomBackgrounds(
  width: number,
  height: number,
  config: Array<Object>,
): Object {
  return config
    .reduce((accumulator, item) => {
      const { artwork } = item;

      if (
        artwork.width.min <= width &&
        artwork.width.max >= width &&
        artwork.height.min <= height &&
        artwork.height.max >= height
      ) {
        const diff = { width: artwork.width.max - width, height: artwork.height.max - height };

        return [
          ...accumulator,
          { ...item, score: diff.width > diff.height ? diff.height : diff.width },
        ];
      }
      return accumulator;
    }, [])
    .sort((a, b) => a.score - b.score);
}
