export const SubscribeFormModel = {
  settings: {
    hideRequiredMark: true,
    layout: 'horizontal',
  },
  fields: [
    {
      tag: 'input',
      type: 'email',
      name: 'emailSubscribe',
      validateTrigger: 'onBlur',
      rules: [
        {
          type: 'email',
          message: 'forms.common.validation_messages.not_valid_email',
        },
        {
          required: true,
          message: 'forms.common.validation_messages.not_empty_email',
        },
      ],
      placeholder: 'forms.subscribeForm.placeholder',
    },
  ],
};
