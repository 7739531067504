// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { parseNumberToLocaleString } from 'shared_services/riseart/utils/Utils';

type Props = {
  currentPage: number,
  itemsPerPage: number,
  totalItems: number,
};

/**
 * SliderPaginationCounter
 *
 * @param {Props} props
 */
export const SliderPaginationCounter = ({ currentPage, itemsPerPage, totalItems }: Props) => {
  const { name: locale } = useLocale() || {};
  let counterData = null;

  if (currentPage) {
    const from = (currentPage - 1) * itemsPerPage;
    const to = from + itemsPerPage;

    counterData = {
      from: parseNumberToLocaleString(from + 1, locale),
      to: parseNumberToLocaleString(to < totalItems ? to : totalItems, locale),
      total: totalItems,
      totalFormatted: parseNumberToLocaleString(totalItems, locale),
    };
  }

  if (!counterData) {
    return null;
  }

  return (
    <FormattedMessage id="components.pagination.sliderCounter" values={counterData}>
      {(text: string) => text}
    </FormattedMessage>
  );
};
