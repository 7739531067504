// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { CartPaymentCardNumber } from '@riseart/cart';
import {
  CheckoutDetails,
  CheckoutDetailsRow,
} from 'shared_components/checkout/sections/info/Details';
import { CARD_TYPE_TRANSLATION_MAPPER } from 'shared_models/forms/checkout/Card';

type Props = { order: Object };

/**
 * PaymentDetailDatacash
 *
 * @param {Props} props
 * @returns {Node}
 */
export function PaymentDetailDatacash({ order }: Props): Node {
  return (
    <CheckoutDetails>
      <CheckoutDetailsRow>
        <div>
          <FormattedMessage
            id="components.checkout.paymentDescription"
            values={{
              paymentType: (
                <FormattedMessage id={`components.checkout.paymentType.${order.paymentType}`}>
                  {(text: string) => text}
                </FormattedMessage>
              ),
            }}
          >
            {(text: string) => text}
          </FormattedMessage>
        </div>
        {order.creditsAmount ? (
          <div>
            <FormattedMessage id="components.checkout.usedCredits">
              {(text: string) => text}
            </FormattedMessage>
          </div>
        ) : null}
        {order.savedPaymentCardType || order.paymentCardType ? (
          <FormattedMessage
            id={`components.checkout.cards.${
              CARD_TYPE_TRANSLATION_MAPPER[order.savedPaymentCardType || order.paymentCardType] ||
              'default'
            }`}
          >
            {(text: string) => text}
          </FormattedMessage>
        ) : null}
        {order.savedPaymentCardLast4 || order.paymentCardLastDigits ? (
          <Fragment>
            {' '}
            {/* eslint-disable-line*/}
            <CartPaymentCardNumber
              number={order.savedPaymentCardLast4 || order.paymentCardLastDigits}
            />
          </Fragment>
        ) : null}
      </CheckoutDetailsRow>
    </CheckoutDetails>
  );
}
