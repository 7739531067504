// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '@riseart/common';
import { CartPaymentOptionsInfo } from '@riseart/cart';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';

type Props = {
  cards: Array<string>,
  canBuyInInstalments?: boolean,
};

/**
 * CartPaymentInfoSection
 *
 * @param {Props} props
 */
export const CartPaymentInfoSection = ({
  cards = [],
  canBuyInInstalments = false,
}: Props): Node => (
  <FormattedMessage id="components.cart.paymentOptions">
    {(paymentOptionsText: string) => (
      <CartPaymentOptionsInfo
        paymentOptionsLabel={paymentOptionsText}
        cards={cards}
        instalmentsDescription={
          canBuyInInstalments ? (
            <React.Fragment>
              <FormattedMessage id="components.cart.instalmentsHint">
                {(instalmentsHint: string) => <span>{instalmentsHint}</span>}
              </FormattedMessage>

              <FormattedMessage id="common.findOutMore">
                {(findMoreText: string) => (
                  <React.Fragment>
                    {findMoreText}
                    <FormattedMessage id="common.here">
                      {(here: string) => (
                        <Link
                          external
                          target="_blank"
                          href={LocalizedConfig.get('externalUrls.instalmentsHelp')}
                        >
                          {here}
                        </Link>
                      )}
                    </FormattedMessage>
                    .
                  </React.Fragment>
                )}
              </FormattedMessage>
            </React.Fragment>
          ) : null
        }
      />
    )}
  </FormattedMessage>
);
