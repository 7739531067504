// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import { Link } from '@riseart/common';
import { cart as ENUM_CART } from 'Enum';
import { withGUIState } from 'shared_hocs/gui/withGuiState';
import { GUI_PROPERTIES } from 'shared_models/Gui';
import { RouteConfigContext } from 'shared_data/providers/routeconfig/RouteConfigContext';

type Props = {
  children: Node,
  actionGuiUpdate: Function,
  pageSidebar: ?boolean,
  onClick?: Function,
};

/**
 * CartLinkComponent
 */
class CartLinkComponent extends Component<Props> {
  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.bindMethods();
  }

  /**
   * handleClick
   *
   * @params {Event} e
   */
  handleClick: Function;

  handleClick(e: Event) {
    e.preventDefault();
    const { actionGuiUpdate, pageSidebar, onClick } = this.props;

    actionGuiUpdate(GUI_PROPERTIES.CART_SIDEBAR, {
      opened: !pageSidebar,
      trigger: ENUM_CART.sidebar.trigger.TRIGGER_SECONDARY_MENU,
    });

    if (typeof onClick === 'function') {
      onClick(e);
    }
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * render
   */
  render() {
    const { children, actionGuiUpdate, pageSidebar, onClick, ...restProps } = this.props;

    return (
      <RouteConfigContext.Consumer>
        {({ cartMenuAction }) => {
          // Route to cart page if cartMenuAction === 'page', otherwise open the cart sidebar
          const isCartPage = cartMenuAction === 'page';

          return (
            <Link
              {...restProps}
              {...(isCartPage ? null : { onClick: this.handleClick })}
              {...(restProps.obfuscate && !isCartPage
                ? { obflinkProps: { stopPropagation: true } }
                : null)}
            >
              {this.props.children}
            </Link>
          );
        }}
      </RouteConfigContext.Consumer>
    );
  }
}

export const CartMenuLink = withGUIState()(CartLinkComponent);
