// @flow

import React, { useEffect } from 'react';
import type { Node } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import type { MenuProps } from 'shared_components/layouts/header/MainMenu';
import { Link } from '@riseart/common';
import { MenuHorizontal, MenuHorizontalItem } from '@riseart/layout';
import { WishlistIcon, BagIcon, ProfileIcon, GiftCardIcon } from '@riseart/icons';
import { addBodyClassName } from '@riseart/fe-utils';
import { meta as META_ENUM, user as USER_ENUM, visitor as VISITOR_ENUM } from 'Enum';
import { application as CONFIG_APP } from 'Config';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { selectVisitorProperty } from 'shared_services/redux/selectors/visitor';
import { selectUserProperty } from 'shared_services/redux/selectors/user';
import { selectAuthShippingCountryCode } from 'shared_services/redux/selectors/auth';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';
import { Avatar } from 'shared_components/common/avatar/Avatar';
import { CartMenuLink } from 'shared_components/cart/MenuLink';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { toggleRecentArtworksWidget } from 'shared_components/arts/widgets/RecentArtworks';
import { RegionalLabel } from 'shared_components/common/regional/Label';

import { headerCountryCls } from 'shared_components/layouts/header/Header.less';
import {
  raVisibilityShowForMedium,
  raVisibilityShowForLarge,
} from '@riseart/antd-provider/dist/website/variables.less';
import { disableScrollCls } from 'shared_styles/app.less';

type ProfileMenuProps = {
  user: Object | null,
  visitorRole: string,
  showLabel?: boolean,
  isMobile?: boolean,
  showRecentWidgetOnPage?: boolean,
};

/**
 * ProfileMenuTitle
 *
 * @param {ProfileMenuProps} props
 * @returns {Node}
 */
const ProfileMenuTitle = ({ user, visitorRole, showLabel, isMobile }: ProfileMenuProps): Node => {
  const { formatMessage } = useIntl();
  const profileLabel = user && formatMessage({ id: 'layouts.header.topmenu.profile' });
  const userName =
    (user && user.firstName) || formatMessage({ id: 'layouts.header.topmenu.avatarLabel' });
  const textLabel = showLabel ? userName : '';

  return user ? (
    <Link
      title={formatMessage({ id: 'layouts.header.topmenu.profile' })}
      to={UrlAssembler.byRouteKey('myProfile')}
    >
      <Avatar size="small" images={user.avatars} name={userName} style={{ cursor: 'pointer' }}>
        {textLabel}
      </Avatar>
    </Link>
  ) : (
    <Link
      to={UrlAssembler.byRouteKey(
        [
          VISITOR_ENUM.role.ROLE_USER,
          VISITOR_ENUM.role.ROLE_ARTIST,
          VISITOR_ENUM.role.ROLE_CURATOR,
        ].indexOf(visitorRole) > -1
          ? 'login'
          : 'register',
      )}
      onClick={(e) => {
        if (isMobile) {
          e.preventDefault();
        }
      }}
      title={textLabel || profileLabel}
      aria-label={profileLabel}
    >
      <ProfileIcon />
      {textLabel}
    </Link>
  );
};

/**
 * SideMenu
 *
 * @param {MenuProps} param0
 * @returns {Node}
 */
export function SideMenu({
  mobile,
  theme,
  active,
  state,
  showRecentWidgetOnPage,
  onActiveItem,
}: MenuProps): Node {
  const dispatch: Function = useDispatch();
  const storeCode = useStoreCode() || CONFIG_APP.store.default;
  const shippingCountryCode = useSelector(selectAuthShippingCountryCode);
  const visitorRole = useSelector(selectVisitorProperty('role'));
  const userRole = useSelector(selectUserProperty('role'));
  const userFirstName = useSelector(selectUserProperty('firstName'));
  const userAvatars = useSelector(selectUserProperty('avatars'));
  const { formatMessage } = useIntl();

  useEffect(() => {
    // Disable scrolling on mount
    addBodyClassName(disableScrollCls, mobile && active);

    // Enable scrolling on unmount
    return () => addBodyClassName(disableScrollCls, false);
  }, [active, mobile]);

  const menuItems = [
    {
      className: raVisibilityShowForLarge,
      itemComponent: (
        <ProfileMenuTitle
          user={
            userRole ? { role: userRole, firstName: userFirstName, avatars: userAvatars } : null
          }
          visitorRole={visitorRole}
        />
      ),
      items: userRole
        ? [
            {
              label: formatMessage({ id: 'layouts.header.topmenu.profile' }),
              href: UrlAssembler.byRouteKey('myProfile'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            },
            {
              label: formatMessage({ id: 'layouts.header.topmenu.account' }),
              href: UrlAssembler.byRouteKey('myAccount'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            },
            {
              label: formatMessage({ id: 'layouts.header.topmenu.myPrivacy' }),
              href: UrlAssembler.byRouteKey('myPrivacy'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            },
            {
              label: formatMessage({ id: 'layouts.header.topmenu.myOffers' }),
              href: UrlAssembler.byRouteKey('myOffers'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            },
            ...(userRole === USER_ENUM.role.ROLE_ARTIST
              ? [
                  {
                    label: formatMessage({ id: 'layouts.header.topmenu.sellerConsole' }),
                    href: LocalizedConfig.get('externalUrls.sellerConsole'),
                    external: true,
                    target: '_blank',
                    rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                  },
                ]
              : []),
            {
              label: formatMessage({ id: 'layouts.header.topmenu.signOut' }),
              href: {
                pathname: UrlAssembler.byRouteKey('signout'),
                state: { forward: LocationManager.get('search') },
              },
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            },
          ]
        : [
            {
              label: formatMessage({ id: 'layouts.header.topmenu.signIn' }),
              href: UrlAssembler.byRouteKey('login'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
              obfuscate: true,
            },
            {
              label: formatMessage({ id: 'layouts.header.topmenu.signUp' }),
              href: UrlAssembler.byRouteKey('register'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
              obfuscate: true,
            },
          ],
    },
    {
      className: raVisibilityShowForLarge,
      itemComponent: (
        <Link
          title={formatMessage({ id: 'layouts.header.topmenu.myWishlist' })}
          to={UrlAssembler.byRouteKey('myWishlist')}
          obfuscate
          obflinkProps={{ stopPropagation: true }}
        >
          <WishlistIcon />
        </Link>
      ),
      items: userRole
        ? [
            {
              label: formatMessage({ id: 'layouts.header.topmenu.myWishlist' }),
              href: UrlAssembler.byRouteKey('myWishlist'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            },
            {
              label: formatMessage({ id: 'layouts.header.topmenu.myArtists' }),
              href: UrlAssembler.byRouteKey('myArtists'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            },
            {
              label: formatMessage({ id: 'layouts.header.topmenu.recentArt' }),
              href: '',
              onClick: (e: Event, props: Object) => {
                e.preventDefault();

                if (props && props.onLinkClick) {
                  props.onLinkClick();
                }

                toggleRecentArtworksWidget(true, dispatch, showRecentWidgetOnPage);
              },
            },
          ]
        : [
            {
              label: formatMessage({ id: 'layouts.header.topmenu.myWishlist' }),
              href: UrlAssembler.byRouteKey('myWishlist'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
              obfuscate: true,
            },
            {
              label: formatMessage({ id: 'layouts.header.topmenu.myArtists' }),
              href: UrlAssembler.byRouteKey('myArtists'),
              rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
              obfuscate: true,
            },
            {
              label: formatMessage({ id: 'layouts.header.topmenu.recentArt' }),
              href: '',
              obfuscate: true,
              onClick: (e, props) => {
                e.preventDefault();

                if (props && props.onLinkClick) {
                  props.onLinkClick();
                }

                toggleRecentArtworksWidget(true, dispatch, showRecentWidgetOnPage);
              },
            },
          ],
    },
    {
      className: raVisibilityShowForLarge,
      itemComponent: (
        <Link
          external
          to={LocalizedConfig.get('externalUrls.giftCard')}
          title={formatMessage({ id: 'layouts.header.topmenu.giftCard' })}
        >
          <GiftCardIcon />
        </Link>
      ),
    },
    {
      itemComponent: (
        <CartMenuLink
          title={formatMessage({ id: 'layouts.header.topmenu.basket' })}
          to={UrlAssembler.cart('cart')}
          rel={META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW}
          obfuscate
        >
          <BagIcon />
        </CartMenuLink>
      ),
    },
    {
      className: raVisibilityShowForMedium,
      itemComponent: (
        <RegionalLabel
          className={headerCountryCls}
          countryCode={shippingCountryCode}
          storeCode={storeCode}
        />
      ),
    },
  ];

  return (
    <MenuHorizontal
      sidemenu
      active={active}
      theme={theme}
      state={state}
      onActiveItem={onActiveItem}
    >
      {menuItems.map((props: Object, index) => {
        const { submenuContent, ...restProps } = props;

        return (
          <MenuHorizontalItem
            {...restProps}
            submenuContent={
              typeof submenuContent === 'function'
                ? submenuContent(() => onActiveItem && onActiveItem({ ...props, index }, 'close'))
                : submenuContent
            }
            key={index}
            index={index}
            opened={state.openedItems.indexOf(index) > -1}
            onActiveItem={onActiveItem}
          />
        );
      })}
    </MenuHorizontal>
  );
}
