// @flow

import React from 'react';
import type { Node } from 'react';

/**
 * HighlightString
 *
 * @param {{ haystack: String, text: string }} props
 * @returns {Node}
 */
export function HighlightString({ haystack, text }: { haystack: String, text: string }): Node {
  return (
    <span
      dangerouslySetInnerHTML={{ __html: haystack.replace(text, `<strong>${text}</strong>`) }}
    />
  );
}
