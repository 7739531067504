// @flow

import React, { useEffect, useState, useCallback, useRef } from 'react';
import type { Node as ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  StickyDrawer,
  MediaQuery,
  ClickOutsideListener,
  Button,
  useMediaQuery,
} from '@riseart/common';
import { Hamburger, MenuVerticalOverlay } from '@riseart/layout';
import { BackIcon } from '@riseart/icons';
import { ssr as SSR_CONFIG } from 'Config';
import { WithOffsetHeight } from 'shared_hocs/gui/withOffsetHeight';
import { calculateOverlayOffset } from 'shared_services/riseart/utils/Utils';
import Logo from 'shared_components/common/logo/Logo';
import { MainMenu } from 'shared_components/layouts/header/MainMenu';
import { SideMenu } from 'shared_components/layouts/header/SideMenu';
import { VerticalMenu } from 'shared_components/layouts/header/VerticalMenu';
import { HeaderSearch } from 'shared_components/layouts/header/Search';
import { HeaderSearchResults } from 'shared_components/layouts/header/SearchResults';

import { raScreenMdMax } from '@riseart/antd-provider/dist/website/variables.less';

import {
  headerCls,
  headerActiveCls,
  headerTransparentCls,
  headerContainerCls,
  headerStickyDrawerCls,
  headerRowCls,
  headerRowFirstCls,
  headerRowSearchFocusedCls,
  headerColumnLeftCls,
  headerColumnCenterCls,
  headerColumnRightCls,
  headerSearchBackBtnCls,
} from 'shared_components/layouts/header/Header.less';

type Props = {
  active?: boolean,
  transparent?: boolean,
  showRecentWidgetOnPage?: boolean,
  disableSticky?: boolean,
};

/**
 * Header
 *
 * @param {Props} props
 * @returns
 */
export function Header({
  active = false,
  transparent: transparentProp = false,
  showRecentWidgetOnPage = false,
  disableSticky = false,
}: Props): ReactNode {
  const isRouteChanged = useRef(false);
  const isMobileScreen = useMediaQuery({ maxWidth: raScreenMdMax });
  const { pathname, search } = useLocation();
  const newLocation = `${pathname}${search}`;
  const [searchValue, setSearchValue] = useState(null);
  const [currentLocation, setCurrentLocation] = useState<string | null>(null);
  const [skipUpdate, setSkipUpdate] = useState<boolean | null>(null);
  const [isStickyFixed, setIsStickyFixed] = useState(false);
  const [isActive, setIsActive] = useState(active);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [mainMenuState, setMainMenuState] = useState<Object>({
    active: false,
    openedItems: [],
    openedItemsDetails: [],
  });
  const [sideMenuState, setSideMenuState] = useState<Object>({
    active: false,
    openedItems: [],
    openedItemsDetails: [],
  });
  const [isVerticalMenuOpened, setIsVerticalMenuOpened] = useState(false);
  const hasAnyActiveMenuItems = useCallback(() => {
    return mainMenuState.openedItems.length || sideMenuState.openedItems.length;
  }, [mainMenuState, sideMenuState]);
  const transparent = transparentProp && !searchValue && !isStickyFixed;

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  useEffect(() => {
    if (currentLocation !== null && currentLocation !== newLocation) {
      // Reset state if new url is loaded
      setIsActive(false);
      setMainMenuState({ active: false, openedItems: [], openedItemsDetails: [] });
      setSideMenuState({ active: false, openedItems: [], openedItemsDetails: [] });
      setIsVerticalMenuOpened(false);
      setCurrentLocation(newLocation);
      setSkipUpdate(true);
    }

    if (currentLocation === null) {
      setCurrentLocation(newLocation);
    }
  }, [currentLocation, newLocation, skipUpdate]);

  const handleMouseEnter = () => {
    setIsActive(true);

    // If location is not set then consider this a route change
    if (currentLocation === null) {
      isRouteChanged.current = true;
    }
  };

  const handleMouseLeave = useCallback(
    (e: Object) => {
      const formElm = document.querySelector('.ra-header-menu-regional form');
      const target = e.relatedTarget;

      if (
        formElm &&
        target instanceof Node &&
        formElm instanceof Node &&
        formElm.contains(target)
      ) {
        return;
      }

      if (skipUpdate) {
        return;
      }

      setIsActive(false);
    },
    [skipUpdate],
  );

  const handleActiveMainMenu = (
    itemProps: Object,
    trigger: 'mouseleave' | 'mouseenter' | 'click' | 'close',
    multiple?: boolean = false,
  ) => {
    // Do not activate menu if route change is detected
    if (isRouteChanged.current) {
      isRouteChanged.current = false;
      return;
    }
    if (skipUpdate === true || skipUpdate === null) {
      if (trigger === 'mouseleave') {
        setSkipUpdate(false);

        if (mainMenuState.openedItems.indexOf(itemProps.index) <= -1) {
          return;
        }
      }
    }

    const { index: idx } = itemProps;

    // Clear side menu state
    if (mainMenuState.openedItems.indexOf(idx) < 0) {
      setSideMenuState((prevState) => {
        return { ...prevState, openedItems: [], openedItemsDetails: [] };
      });
    }

    setMainMenuState((prevState) => {
      const { openedItems, openedItemsDetails, ...prevStateRest } = prevState || {};

      return openedItems.indexOf(idx) < 0
        ? {
            ...prevStateRest,
            openedItems: [...(multiple ? openedItems : []), idx],
            openedItemsDetails: [...(multiple ? openedItemsDetails : []), itemProps],
          }
        : {
            ...prevStateRest,
            openedItems: openedItems.filter((openIndex) => openIndex !== idx),
            openedItemsDetails: openedItemsDetails.filter(({ index }) => index !== idx),
          };
    });

    if (trigger === 'close') {
      setIsActive(false);
    }
  };

  const handleActiveSideMenu = (
    itemProps: Object,
    trigger: 'mouseleave' | 'mouseenter' | 'click' | 'close',
    multiple?: boolean = false,
  ) => {
    if (skipUpdate === true || skipUpdate === null) {
      if (trigger === 'mouseleave') {
        setSkipUpdate(false);
        if (sideMenuState.openedItems.indexOf(itemProps.index) <= -1) {
          return;
        }
      }
    }

    const { index: idx } = itemProps;

    if (
      !isMobileScreen &&
      (sideMenuState.openedItemsDetails[0] || {}).clickable === true &&
      (!itemProps.items || (itemProps.items && !itemProps.items.length)) &&
      !itemProps.submenuContent
    ) {
      return;
    }

    // Clear main menu state
    if (sideMenuState.openedItems.indexOf(idx) < 0) {
      setMainMenuState((prevState) => {
        return { ...prevState, openedItems: [], openedItemsDetails: [] };
      });
    }

    setSideMenuState((prevState) => {
      const { openedItems, openedItemsDetails, ...prevStateRest } = prevState || {};

      return openedItems.indexOf(idx) < 0
        ? {
            ...prevStateRest,
            openedItems: [...(multiple ? openedItems : []), idx],
            openedItemsDetails: [...(multiple ? openedItemsDetails : []), itemProps],
          }
        : {
            ...prevStateRest,
            openedItems: openedItems.filter((openIndex) => openIndex !== idx),
            openedItemsDetails: openedItemsDetails.filter(({ index }) => index !== idx),
          };
    });

    if (trigger === 'close') {
      setIsActive(false);
    }
  };

  const handleOpenVerticalMenu = () => {
    setIsVerticalMenuOpened(!isVerticalMenuOpened);
    setSideMenuState({ active: false, openedItems: [], openedItemsDetails: [] });
  };

  const combinedHeaderState = {
    isActive,
    mainMenuState,
    sideMenuState,
  };

  const isHeaderActive = !!(isActive || hasAnyActiveMenuItems() || searchValue);

  /**
   * Menu click outside feature
   */
  const headerRef: React.RefObject<null | HTMLElement> = useRef(null);

  const handleClickOutside = (e: Object) => {
    const isClickedOutside =
      headerRef && headerRef.current && !headerRef.current.contains(e.target);

    if (isClickedOutside) {
      setIsActive(false);
      setMainMenuState({ active: false, openedItems: [], openedItemsDetails: [] });
      setSideMenuState({ active: false, openedItems: [], openedItemsDetails: [] });
      setIsVerticalMenuOpened(false);
    }
  };

  const handleStickyChange = useCallback(
    ({ top, height, isVisible }: Object) => {
      if (transparentProp && !isActive) {
        setIsStickyFixed(isVisible && Math.abs(top) > height);
      }
    },
    [transparentProp, isActive],
  );

  return (
    <MediaQuery maxWidth={raScreenMdMax}>
      {(isMobile: boolean) => (
        <StickyDrawer
          skip={disableSticky}
          disable={isActive}
          delay={250}
          toggleDelta={50}
          className={headerStickyDrawerCls}
          onChange={handleStickyChange}
        >
          <header
            ref={headerRef}
            className={classNames(headerCls, {
              [headerActiveCls]: isHeaderActive,
              [headerTransparentCls]: transparent,
            })}
            {...(!isMobile
              ? { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }
              : null)}
          >
            {hasAnyActiveMenuItems() ? (
              <ClickOutsideListener onClickOutside={handleClickOutside} />
            ) : null}
            {/* Hamburger Vertical Menu */}
            {isMobile ? (
              <WithOffsetHeight>
                {({ notificationsHeight, applicationsHeight }) => {
                  return (
                    <MenuVerticalOverlay
                      align="right"
                      theme={transparent ? 'white' : 'black'}
                      active={isVerticalMenuOpened}
                      topOffset={calculateOverlayOffset(notificationsHeight, applicationsHeight)}
                      header={<Logo black type="initials" />}
                      onClose={handleOpenVerticalMenu}
                    >
                      <VerticalMenu
                        multiple
                        handleOverlay={setIsVerticalMenuOpened}
                        resetState={!isVerticalMenuOpened}
                        showRecentWidgetOnPage={showRecentWidgetOnPage}
                      />
                    </MenuVerticalOverlay>
                  );
                }}
              </WithOffsetHeight>
            ) : null}

            <div
              className={classNames(
                headerContainerCls,
                (isSearchFocused || searchValue) && isMobile && headerRowSearchFocusedCls,
              )}
            >
              {isMobile ? (
                <div className={headerRowCls}>
                  {/* Mobile Header */}
                  <div className={headerColumnLeftCls}>
                    <Logo
                      tag="h1"
                      black={isHeaderActive || !transparent}
                      type="initials"
                      headingProps={{ id: SSR_CONFIG.htmlIDs.pageTitle }}
                      showTitle
                    />
                  </div>
                  <div className={headerColumnCenterCls}>
                    <HeaderSearch
                      onChange={setSearchValue}
                      onFocus={setIsSearchFocused}
                      prefixChild={({ handleClear, setIsFocused }) => (
                        <Button
                          type="ghost"
                          size="small"
                          className={headerSearchBackBtnCls}
                          onClick={() => {
                            handleClear();
                            setIsFocused(false);
                          }}
                        >
                          <BackIcon />
                        </Button>
                      )}
                    />
                  </div>
                  <div className={headerColumnRightCls}>
                    <SideMenu
                      mobile
                      theme={transparent ? 'white' : 'black'}
                      active={isHeaderActive}
                      state={{ ...combinedHeaderState, openedItems: sideMenuState.openedItems }}
                      onActiveItem={handleActiveSideMenu}
                      showRecentWidgetOnPage={showRecentWidgetOnPage}
                    />
                    <Hamburger
                      theme={transparent && !isHeaderActive ? 'white' : 'black'}
                      isOpened={isVerticalMenuOpened}
                      hamburgerHandler={handleOpenVerticalMenu}
                    />
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className={classNames(headerRowCls, headerRowFirstCls)}>
                    {/* Desktop Header */}
                    <div className={headerColumnLeftCls}>
                      <Logo
                        tag="h1"
                        black={isHeaderActive || !transparent}
                        headingProps={{ id: SSR_CONFIG.htmlIDs.pageTitle }}
                        showTitle
                      />
                    </div>
                    <div className={headerColumnCenterCls}>
                      <HeaderSearch onChange={setSearchValue} onFocus={setIsSearchFocused} />
                    </div>
                    <div className={headerColumnRightCls}>
                      <SideMenu
                        theme={transparent ? 'white' : 'black'}
                        active={isHeaderActive}
                        state={{ ...combinedHeaderState, openedItems: sideMenuState.openedItems }}
                        onActiveItem={handleActiveSideMenu}
                        showRecentWidgetOnPage={showRecentWidgetOnPage}
                      />
                    </div>
                  </div>
                  <div className={headerRowCls}>
                    <div className={headerColumnLeftCls} />
                    <div className={headerColumnCenterCls}>
                      <MainMenu
                        theme={transparent ? 'white' : 'black'}
                        active={isHeaderActive}
                        state={{
                          ...combinedHeaderState,
                          // Do not open any submenus if search is focused
                          openedItems: isSearchFocused ? [] : mainMenuState.openedItems,
                        }}
                        onActiveItem={handleActiveMainMenu}
                        showRecentWidgetOnPage={showRecentWidgetOnPage}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            {searchValue ? (
              <WithOffsetHeight>
                {({ headerHeight, notificationsHeight, applicationsHeight }) => (
                  <HeaderSearchResults
                    topOffset={
                      calculateOverlayOffset(notificationsHeight, applicationsHeight) + headerHeight
                    }
                    q={searchValue}
                  />
                )}
              </WithOffsetHeight>
            ) : null}
          </header>
        </StickyDrawer>
      )}
    </MediaQuery>
  );
}
