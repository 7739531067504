// @flow

import React from 'react';
import { useIntl } from 'react-intl';
import { FormBuilder } from '@riseart/form';
import { CartGiftCardFormModel } from 'shared_models/forms/CartGiftCard';

type Props = { onSubmit: Function };

/**
 * CartGiftCardForm
 *
 * @param {Props} props
 */
export const CartGiftCardForm = ({ onSubmit }: Props) => {
  const intl = useIntl();

  return (
    <FormBuilder
      settings={CartGiftCardFormModel.settings}
      fields={CartGiftCardFormModel.fields}
      onSubmit={onSubmit}
      submitActionLabel={intl.formatMessage({ id: 'forms.cart.redeemGiftCard' })}
      customData={{}}
      intl={intl}
    />
  );
};
