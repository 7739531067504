// @flow

import React from 'react';
import type { Node } from 'react';
import { find } from '@riseart/fe-utils';
import { Avatar as CommonAvatar } from '@riseart/common';
import { avatar as ENUM_AVATAR } from 'Enum';
import { Picture } from 'shared_components/common/artdirection/picture/Picture';

type Props = {
  theme?: string,
  align?: string,
  size?: 'small' | 'medium' | 'large',
  className?: string,
  images?: Array<Object>,
  imageObject?: Object,
  name: string,
  children?: Node,
  style?: Object,
};

/**
 * Avatar
 *
 * @param {Props} props
 * @returns {Node}
 */
export const Avatar = ({
  theme = 'black',
  align = 'left',
  size = 'medium',
  className = null,
  images,
  imageObject,
  name,
  children = null,
  style = null,
}: Props): Node => {
  const SIZE = { small: 'small', medium: 'medium', large: 'large' };
  const image =
    imageObject ||
    (images && images.length
      ? find(images, ({ type }) => type === ENUM_AVATAR.image.type.TYPE_SQUARE)
      : null);
  const selectedSize = (size && SIZE[size]) || SIZE.medium;

  return (
    <CommonAvatar
      theme={theme}
      align={align}
      size={selectedSize}
      className={className}
      image={
        image ? (
          <Picture
            type={`avatar.${selectedSize}`}
            artDirectionKey="common.avatar"
            image={image}
            alt={name}
          />
        ) : null
      }
      alt={name}
      name={name}
      style={style}
    >
      {children}
    </CommonAvatar>
  );
};
