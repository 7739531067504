// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { useQuery } from 'shared_services/apollo/useQuery';
import { DataProviderPreloaderPage } from 'shared_components/data/providers/preloaders/Page';

type Props = {
  query: any,
  variables: Object,
  dataHandler?: Function,
  render?: Function,
  subscribeToPreloader?: boolean,
  preloadSubscriberId?: string,
  children?: any,
  skip?: boolean,
  ssr?: boolean,
  placeholder?: Node,
};

/**
 * ListArtQuery
 *
 * @param {Props} props
 */
export const ListArtQuery = ({
  query,
  variables,
  dataHandler = (data: any) => data,
  render = undefined,
  children = null,
  subscribeToPreloader = true,
  preloadSubscriberId,
  placeholder = null,
  skip = false,
  ssr = false,
}: Props): Node => {
  const queryResponse = useQuery(query, {
    ssr,
    skip,
    variables,
    context: {
      customOptions: {
        errorFilter: (errorList = []) =>
          errorList.filter((error) => error && error.errorType !== 'ResourceNotFoundException'),
      },
    },
  });
  const { loading, error, data } = queryResponse;

  if (render) {
    return render(queryResponse);
  }

  if (subscribeToPreloader && loading && !error) {
    return (
      <Fragment>
        <DataProviderPreloaderPage uniqueId={preloadSubscriberId} attach={loading} />
        {placeholder}
      </Fragment>
    );
  }

  if (loading || error) {
    return null;
  }

  const responseData = (typeof dataHandler === 'function' && dataHandler(data)) || data;
  return (children && children(responseData && responseData.items, data)) || null;
};
