// @flow

import React, { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { ViewInRoomPreview } from '@riseart/art';
import { Picture } from 'shared_components/common/artdirection/picture/Picture';

type Props = Object;

export function DetailViewInRoomWrapper({
  // image,
  previewConfig,
  artWidth,
  artHeight,
  children,
}: Props): Node {
  const wrapperEl = useRef(null);
  const [containerHeight, setContainerHeight] = useState(null);

  useEffect(() => {
    if (wrapperEl.current) {
      const { width } = wrapperEl.current.getBoundingClientRect();
      setContainerHeight(
        previewConfig.background.height * (width / previewConfig.background.width),
      );
    }
  }, [containerHeight, previewConfig.background.height, previewConfig.background.width]);

  return (
    <div ref={wrapperEl} style={containerHeight ? { height: `${containerHeight}px` } : null}>
      {containerHeight ? (
        <ViewInRoomPreview
          backgroundPicture={({ config, imgProps }) => (
            <FormattedMessage id="components.art.viewInRoomBkgAlt">
              {(alt: string) => (
                <Picture
                  type="art.imageActionSecondary"
                  artDirectionKey="inRoomBackground.main"
                  image={config.background}
                  alt={alt}
                  imgProps={{ ...imgProps }}
                />
              )}
            </FormattedMessage>
          )}
          config={previewConfig}
          width={artWidth}
          height={artHeight}
        >
          {children}
        </ViewInRoomPreview>
      ) : null}
    </div>
  );
}
