// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Link, MediaQuery } from '@riseart/common';
import { VisuallySimilarIcon } from '@riseart/icons';
import { Section, SectionContent, Wrapper } from '@riseart/layout';
import { analytics as ANALYTICS_ENUM } from 'Enum';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';
import { ListArtQuery as ListQuery } from 'shared_data/providers/queries/ListArtQuery';
import { ArtSlider } from 'shared_components/arts/slider/Slider';
import { GridNoResults } from 'shared_components/arts/containers/grid/NoResults';
import { ArtistArtSlider } from 'shared_components/artist/art/Slider';
import { parseNumberToLocaleString } from 'shared_services/riseart/utils/Utils';
import { SliderPaginationCounter } from 'shared_components/common/pagination/counter/Slider';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import SEARCH_ART_QUERY from 'shared_data/queries/search/art.graphql';
import SEARCH_ARTISTS_QUERY from 'shared_data/queries/search/artists.graphql';

import { raScreenMd } from '@riseart/antd-provider/dist/website/variables.less';
import {
  searchResultsCls,
  searchResultsSectionTopCls,
  searchResultsSectionTopSummaryCls,
  searchResultsSectionArtistsCls,
  searchResultsSectionActionCls,
  searchResultsSectionArtworksCls,
} from 'shared_components/search/Results.less';

type Props = { topOffset?: number, q: ?string };

/**
 * SearchResults
 *
 * @param {Object} props
 */
export function SearchResults({ topOffset, q }: Props): Node {
  const TOTAL_ITEMS = 16;
  const hasTextQuery = !!q;
  const storeCode = useStoreCode();
  const { name: locale } = useLocale() || {};
  const artistBasicTitle = <FormattedMessage id="components.search.artistTitleBasic" />;
  const artworkBasicTitle = <FormattedMessage id="components.search.artworkTitleBasic" />;
  const SearchArtistsPlaceholder = (
    <Section background="gray" className={searchResultsSectionArtistsCls}>
      <Wrapper>
        <SectionContent title={artistBasicTitle}>
          <ArtistArtSlider placeholder columnCount="3" />
        </SectionContent>
      </Wrapper>
    </Section>
  );
  const SearchArtworksPlaceholder = (
    <Section className={searchResultsSectionArtworksCls}>
      <Wrapper>
        <SectionContent title={artworkBasicTitle}>
          <ArtSlider columnCount="4" cardDisplayStyle="7" placeholder />
        </SectionContent>
      </Wrapper>
    </Section>
  );

  return (
    <div
      className={searchResultsCls}
      {...(topOffset ? { style: { height: `calc(100vh - ${topOffset}px)` } } : null)}
    >
      <Section background="white" padding="none" className={searchResultsSectionTopCls}>
        <Wrapper>
          <div>
            <FormattedMessage id="forms.search.yourSearchResults">
              {(label: string) => (
                <span className={searchResultsSectionTopSummaryCls}>
                  {label} <span>{q}</span>
                </span>
              )}
            </FormattedMessage>
            <MediaQuery minWidth={raScreenMd}>
              <FormattedMessage
                id="forms.search.visual.searchHintButton"
                values={{
                  icon: () => <VisuallySimilarIcon />,
                }}
              >
                {(label: string) => (
                  <Button
                    type="secondary"
                    size="small"
                    width="auto"
                    isAnchor
                    to={UrlAssembler.artList({ search: { v: '' } })}
                  >
                    {label}
                  </Button>
                )}
              </FormattedMessage>
            </MediaQuery>
          </div>
        </Wrapper>
      </Section>

      {hasTextQuery ? (
        <Fragment>
          <ListQuery
            query={SEARCH_ARTISTS_QUERY}
            variables={{
              inputArtistFilter: {
                page: 1,
                items: TOTAL_ITEMS,
                sort: 'relevance',
                artworks: true,
              },
              textQuery: q,
            }}
            skip={!q}
            dataHandler={(data) => data && data.searchArtists}
            placeholder={SearchArtistsPlaceholder}
          >
            {(items, responseData) => {
              const hasItems = items && items.length;
              const totalItems =
                (responseData &&
                  responseData.searchArtists &&
                  responseData.searchArtists.pagination &&
                  responseData.searchArtists.pagination.totalItems) ||
                null;

              return (
                <Section background="gray" className={searchResultsSectionArtistsCls}>
                  <Wrapper>
                    <SectionContent
                      title={
                        hasItems ? (
                          <FormattedMessage
                            id="components.search.artistTitle"
                            values={{ count: parseNumberToLocaleString(totalItems, locale) }}
                          />
                        ) : (
                          artistBasicTitle
                        )
                      }
                      link={
                        <MediaQuery minWidth={raScreenMd}>
                          {(isLargeScreen) => (
                            <div className={searchResultsSectionActionCls}>
                              {isLargeScreen ? (
                                <SliderPaginationCounter
                                  currentPage={1}
                                  itemsPerPage={TOTAL_ITEMS}
                                  totalItems={totalItems}
                                />
                              ) : null}
                              {hasItems ? (
                                <FormattedMessage id="components.search.searchByDomainButton">
                                  {(label: string) =>
                                    (isLargeScreen && (
                                      <Button
                                        type="secondary"
                                        size="small"
                                        width="auto"
                                        isAnchor
                                        href={UrlAssembler.artistList({ search: { q } })}
                                      >
                                        {label}
                                      </Button>
                                    )) || (
                                      <Link
                                        type="strong"
                                        to={UrlAssembler.artistList({ search: { q } })}
                                        title={label}
                                      >
                                        {label}
                                      </Link>
                                    )
                                  }
                                </FormattedMessage>
                              ) : null}
                            </div>
                          )}
                        </MediaQuery>
                      }
                    >
                      {hasItems ? (
                        <ArtistArtSlider items={items} columnCount="3" />
                      ) : (
                        <GridNoResults>
                          <FormattedMessage id="components.search.noArtists">
                            {(label: string) => label}
                          </FormattedMessage>
                        </GridNoResults>
                      )}
                    </SectionContent>
                  </Wrapper>
                </Section>
              );
            }}
          </ListQuery>
          <ListQuery
            query={SEARCH_ART_QUERY}
            variables={{
              inputArtFilter: {
                page: 1,
                items: TOTAL_ITEMS,
                sort: 'relevance',
                store: storeCode,
              },
              textQuery: q,
            }}
            skip={!storeCode || !q}
            dataHandler={(data) => data && data.searchArt}
            placeholder={SearchArtworksPlaceholder}
          >
            {(items, responseData) => {
              const hasItems = items && items.length;
              const totalItems =
                (responseData &&
                  responseData.searchArt &&
                  responseData.searchArt.pagination &&
                  responseData.searchArt.pagination.totalItems) ||
                null;

              return (
                <Section className={searchResultsSectionArtworksCls}>
                  <Wrapper>
                    <SectionContent
                      title={
                        hasItems ? (
                          <FormattedMessage
                            id="components.search.artworkTitle"
                            values={{ count: parseNumberToLocaleString(totalItems, locale) }}
                          />
                        ) : (
                          artworkBasicTitle
                        )
                      }
                      link={
                        <MediaQuery minWidth={raScreenMd}>
                          {(isLargeScreen) => (
                            <div className={searchResultsSectionActionCls}>
                              {isLargeScreen ? (
                                <SliderPaginationCounter
                                  currentPage={1}
                                  itemsPerPage={TOTAL_ITEMS}
                                  totalItems={totalItems}
                                />
                              ) : null}
                              {hasItems ? (
                                <FormattedMessage id="components.search.searchByDomainButton">
                                  {(label: string) =>
                                    (isLargeScreen && (
                                      <Button
                                        type="secondary"
                                        size="small"
                                        width="auto"
                                        isAnchor
                                        href={UrlAssembler.artList({ search: { q } })}
                                      >
                                        {label}
                                      </Button>
                                    )) || (
                                      <Link
                                        type="strong"
                                        to={UrlAssembler.artList({ search: { q } })}
                                        title={label}
                                      >
                                        {label}
                                      </Link>
                                    )
                                  }
                                </FormattedMessage>
                              ) : null}
                            </div>
                          )}
                        </MediaQuery>
                      }
                    >
                      {hasItems ? (
                        <ArtSlider
                          items={items}
                          columnCount="4"
                          cardDisplayStyle="7"
                          showImagePlaceholder
                          lazyload={null}
                          tracking={{
                            enabled: true,
                            enableInitial: false,
                            enableVisibility: true,
                            enableClick: true,
                            name: ANALYTICS_ENUM.list.name.SEARCH_ARTWORKS,
                          }}
                        />
                      ) : (
                        <GridNoResults>
                          <FormattedMessage id="components.search.noArtworks">
                            {(label: string) => label}
                          </FormattedMessage>
                        </GridNoResults>
                      )}
                    </SectionContent>
                  </Wrapper>
                </Section>
              );
            }}
          </ListQuery>
        </Fragment>
      ) : (
        <GridNoResults>
          <FormattedMessage id="components.search.noSearchQuery">
            {(label: string) => label}
          </FormattedMessage>
        </GridNoResults>
      )}
    </div>
  );
}
