// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { CartPaymentCardNumber } from '@riseart/cart';
import { cart as ENUM_CART } from 'Enum';
import {
  CheckoutDetails,
  CheckoutDetailsRow,
} from 'shared_components/checkout/sections/info/Details';
import { CARD_TYPE_TRANSLATION_MAPPER } from 'shared_models/forms/checkout/Card';

type Props = { order: Object };

/**
 * PaymentDetailStripe
 *
 * @param {Props} props
 * @returns {Node}
 */
export function PaymentDetailStripe({ order }: Props): Node {
  const account = order.paymentAccountEmail || order.paymentAccountOwner;
  const isCardPaymentType = order.paymentType === ENUM_CART.paymentType.TYPE_CARD;
  const showPaymentTypeText =
    !isCardPaymentType ||
    (isCardPaymentType && !order.savedPaymentCardType && !order.paymentCardType);

  return (
    <CheckoutDetails>
      <CheckoutDetailsRow>
        {showPaymentTypeText ? (
          <FormattedMessage
            id={`components.checkout.${
              account ? 'paymentDescriptionAccount' : 'paymentDescription'
            }`}
            values={{
              paymentType: (
                <FormattedMessage
                  key="paymentType"
                  id={`components.checkout.paymentType.${order.paymentType || 'stripe'}`}
                >
                  {(text: string) => text}
                </FormattedMessage>
              ),
              account,
            }}
          >
            {(text: string) => text}
          </FormattedMessage>
        ) : null}
        {/* Show paymentCategory information for specific payment types */}
        {order.paymentCategory && order.paymentType === ENUM_CART.paymentType.TYPE_KLARNA ? (
          <FormattedMessage
            id={`components.checkout.paymentCategory.${order.paymentCategory}`}
            defaultMessage=" "
          >
            {(text: string) => (text !== ' ' ? <div>{text}</div> : null)}
          </FormattedMessage>
        ) : null}
        {order.creditsAmount ? (
          <div>
            <FormattedMessage id="components.checkout.usedCredits">
              {(text: string) => text}
            </FormattedMessage>
          </div>
        ) : null}
        {order.savedPaymentCardType || order.paymentCardType ? (
          <div>
            <FormattedMessage
              id={`components.checkout.cards.${
                CARD_TYPE_TRANSLATION_MAPPER[order.savedPaymentCardType || order.paymentCardType] ||
                'default'
              }`}
            >
              {(text: string) => text}
            </FormattedMessage>
          </div>
        ) : null}
        {order.savedPaymentCardLast4 || order.paymentCardLastDigits || order.paymentAccountLast4 ? (
          <Fragment>
            {' '}
            {/* eslint-disable-line*/}
            <CartPaymentCardNumber
              number={
                order.savedPaymentCardLast4 ||
                order.paymentCardLastDigits ||
                order.paymentAccountLast4
              }
            />
          </Fragment>
        ) : null}
      </CheckoutDetailsRow>
    </CheckoutDetails>
  );
}
