// @flow

import React from 'react';
import { Link } from '@riseart/common';
import { TwitterIcon, InstagramIcon, FacebookIcon, PinterestIcon } from '@riseart/icons';
import {
  Footer as FooterContainer,
  FooterSection,
  FooterMenu,
  FooterSocial,
  FooterPartners,
} from '@riseart/layout';
import { FormattedMessage } from 'react-intl';
import { navigation as CONFIG_NAVIGATION } from 'Config';
import { I18nComponentsLoader } from 'shared_components/i18n/ComponentsLoader';
import { SubscribeForm } from 'shared_components/forms/subscribe/Form';
import { CreateMailingListMutation } from 'shared_data/providers/queries/CreateMailingListMutation';
import { OwnArtLink } from 'shared_components/common/ownart/Link';

const SOC_LINKS_KEYS = [
  { key: 'twitter', icon: TwitterIcon },
  { key: 'instagram', icon: InstagramIcon },
  { key: 'facebook', icon: FacebookIcon },
  { key: 'pinterest', icon: PinterestIcon },
];

export const Footer = () => (
  <FormattedMessage id="layouts.footer.title">
    {(title: string) => (
      <FooterContainer title={title}>
        <FooterSection>
          <CreateMailingListMutation>
            {(createMailingList) => (
              <SubscribeForm
                theme="basic"
                placeholderKey="forms.subscribeForm.placeholder"
                buttonKey="forms.subscribeForm.submitButtonText"
                labelKey="forms.single_field_form.label_text"
                inputId="footer-field"
                inputName="footer-email"
                isLabelForm={false}
                subscribeMutation={createMailingList}
              />
            )}
          </CreateMailingListMutation>
        </FooterSection>
        <FooterSection>
          <I18nComponentsLoader dataKey="settings">
            {({ footerLinks }) => <FooterMenu items={footerLinks} />}
          </I18nComponentsLoader>
          <FormattedMessage id="layouts.footer.social.title">
            {(title: string) => (
              <React.Fragment>
                <FooterSocial title={title}>
                  {SOC_LINKS_KEYS.map(({ key, icon: IconComponent }) => (
                    <FormattedMessage key={key} id={`layouts.footer.social.${key}`}>
                      {(text: string) => (
                        <Link
                          external
                          obfuscate
                          href={CONFIG_NAVIGATION.social[key].link}
                          target="_blank"
                          title={text}
                          aria-label={text}
                        >
                          <IconComponent />
                        </Link>
                      )}
                    </FormattedMessage>
                  ))}
                </FooterSocial>
                <FooterPartners>
                  <OwnArtLink />
                </FooterPartners>
              </React.Fragment>
            )}
          </FormattedMessage>
        </FooterSection>
      </FooterContainer>
    )}
  </FormattedMessage>
);
