// @flow

import { useSelector, shallowEqual } from 'react-redux';
import { localeSelector } from 'shared_services/redux/selectors/locale';

/**
 * useLocale
 *
 * @param {string} key?
 * @returns {Object}
 */
export const useLocale = (key?: string): Object => {
  const locale = useSelector(localeSelector, shallowEqual);
  return key ? locale[key] : locale;
};
