// @flow

import React, { useState } from 'react';
import type { Node } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { libs as CONFIG_LIBS, payment as CONFIG_PAYMENT } from 'Config';
import { useLocale } from 'shared_services/redux/hooks/useLocale';

import {
  inputHeight,
  inputBorder,
  inputBorderFocus,
  inputVerticalMargin,
  inputPadding,
  colorBackground,
  colorError,
  colorSuccess,
  paymentBoxBorder,
  paymentOptionTabSelectedBkg,
  colorText,
  colorTextSecondary,
  fontSize,
  fontFamily,
} from 'shared_components/checkout/sections/payment/Payment.less';

type Props = {
  children: Function,
  amount?: number,
};

/**
 * StripeProvider
 *
 * @param {Props} props
 * @returns {Node}
 */
export const StripeProvider = ({ children, amount, ...elementOptions }: Props): Node => {
  const locale = useLocale();
  // load stripe initially and set in state
  const [stipePromise] = useState(() => loadStripe(CONFIG_LIBS.stripe.apiKey));
  const RA_APPEARANCE_OPTIONS = {
    theme: 'flat',
    variables: {
      borderRadius: '0px',
      focusOutline: '0px',
      focusBoxShadow: '0px',
      colorBackground,
      colorText,
      colorTextSecondary,
      colorSuccess,
      colorWarning: colorError,
      fontSizeBase: fontSize,
      fontSizeXl: fontSize,
      fontSizeLg: fontSize,
      fontSizeSm: fontSize,
      fontSizeXs: fontSize,
      fontSize2Xs: fontSize,
      fontSize3Xs: fontSize,
      fontFamily,
      spacingGridRow: inputVerticalMargin,
    },
    rules: {
      '.Block': {
        border: paymentBoxBorder,
        boxShadow: 'none',
      },
      '.BlockDivider': {},
      '.Tab': {},
      '.Tab--selected': {
        backgroundColor: paymentOptionTabSelectedBkg,
        border: 'none',
      },
      '.Tab:focus': {},
      '.Input': {
        borderBottom: inputBorder,
        backgroundColor: 'transparent',
        padding: inputPadding,
        lineHeight: inputHeight,
      },
      '.Input:focus': {
        borderBottom: inputBorderFocus,
        boxShadow: 'none',
        outline: 'none',
      },
      '.Input--invalid': {
        borderBottom: `1px solid ${colorError}`,
        color: colorError,
        boxShadow: 'none',
        outline: 'none',
      },
      '.Error': {
        color: colorError,
      },
      '.Label': {
        letterSpacing: '1px',
        textTransform: 'uppercase',
        fontSize: '13px',
        fontWeight: '700',
      },
    },
  };

  let paymentAmount = null;

  // Amount is required to have a minimum value, and in case an empty value is passed (when no payment is required),
  // the Provider requires the amount to be a value above the minimum set for Stripe
  if (typeof amount === 'number') {
    paymentAmount = amount > 0 ? amount : CONFIG_PAYMENT.stripe.minimumPaymentAmount;
  }

  return (
    <Elements
      stripe={stipePromise}
      // $FlowFixMe
      options={{
        appearance: RA_APPEARANCE_OPTIONS,
        // used locale.language instead of locale.name because Stripe elements do not support locales like fr-FR, de-DE, etc.
        locale: locale && locale.language,
        ...elementOptions,
        ...(paymentAmount ? { amount: paymentAmount } : null),
      }}
    >
      <ElementsConsumer>
        {({ stripe, elements }) => children({ stripe, elements })}
      </ElementsConsumer>
    </Elements>
  );
};
