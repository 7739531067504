// @flow

import type { Node } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { meFetch } from 'shared_services/redux/actions/me/me';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { getAuthForwardDestination } from 'shared_services/riseart/utils/AuthUtils';
import { GTM_EVENT_TRIGGER_REASONS } from 'shared_services/redux/middleware/MiddlewareGoogleTagManager';
import CREATE_MAILING_LIST from 'shared_data/queries/mailinglist/create.graphql';

type Props = {
  onComplete?: Function,
  children: Function,
};

/**
 * CreateMailingListMutation
 *
 * @param {Props} props
 * @returns {Node}
 */
export function CreateMailingListMutation({ children, onComplete, ...restProps }: Props): Node {
  const locale = useLocale();
  const dispatch: Function = useDispatch();
  const dispatchPostSubscribeActions = (data: Object = {}, locale: Object) => {
    const { href, search } = LocationManager.get();
    const queryParams: Object = queryString.parse(search);
    const location =
      queryParams && (queryParams.forward || queryParams.quizResponseId)
        ? getAuthForwardDestination(queryParams, locale)
        : href;

    dispatch(
      meFetch({
        payload: {
          trigger: {
            reason: GTM_EVENT_TRIGGER_REASONS.subscription,
            data: { type: data.type, emailAddress: data.emailAddress, location },
          },
        },
      }),
    );
  };
  const [subscribeMutation, mutationOptions] = useMutation(CREATE_MAILING_LIST, {
    onError: () => null,
    onCompleted: ({ createMailingList }: Object) => {
      if (createMailingList && createMailingList.id) {
        dispatchPostSubscribeActions(createMailingList, locale);
        typeof onComplete === 'function' && onComplete(createMailingList);
      }
    },
    ...restProps,
  });

  return children(subscribeMutation, mutationOptions);
}
