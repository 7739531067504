// @flow

/**
 * getGraphqlOperationName
 *
 * @param {Object} operationType
 * @returns {string} data key of the graphql query
 */
export function getGraphqlOperationName(operationType: Object): string {
  return operationType.definitions[0].name.value;
}

/**
 * cacheUpdateHandler
 *
 * @param {Object} updatedQuery
 * @param {string} dataKey
 * @param {Object} variables?
 * @param {Function} customHandler?
 */
export const cacheUpdateHandler = (
  updatedQuery: Object,
  dataKey: string,
  variables?: Object,
  customHandler?: Function,
) => {
  return (cache: Object, { data }: Object) => {
    const dataByKey = data && data[dataKey];

    // No data for the provided gql operation (mutation) key is found
    if (!dataByKey) {
      return;
    }

    let updatedData = null;

    // Detect by __typename the data to update the cache with
    // eslint-disable-next-line
    switch (dataByKey.__typename) {
      case 'Cart':
        updatedData = dataByKey;
        break;
      case 'CartItemResult':
        updatedData = dataByKey && dataByKey.cart;
        break;
      default:
        updatedData = dataByKey;
        break;
    }

    // Handle custom update to cache, if optional customHandler argument is provided
    if (typeof customHandler === 'function') {
      customHandler(cache, updatedData);
      return;
    }

    if (!updatedData) {
      return;
    }

    // Update cache with new data
    cache.writeQuery({
      query: updatedQuery,
      ...(variables ? { variables } : {}),
      data: {
        [getGraphqlOperationName(updatedQuery)]: updatedData,
      },
    });
  };
};
