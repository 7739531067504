// @flow

import debounce from 'lodash/debounce';
import classNames from 'classnames';
import React, { Fragment, useState, useRef, useEffect, useCallback } from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { Form, FormItem, FormInput, FormSelect, FormSelectOption } from '@riseart/form';
import { Button, Heading } from '@riseart/common';
import { CloseIcon, SearchIcon } from '@riseart/icons';
import { ContinuousSlider } from '@riseart/slider';
import { isTouchDevice } from '@riseart/fe-utils';
import { components as CONFIG_COMPONENTS } from 'Config';
import { search as ENUM_SEARCH } from 'Enum';
import { Query } from 'shared_services/apollo/Query';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';
import { HighlightString } from 'shared_components/common/HighlightString';
import { VisualSearchHint } from 'shared_components/forms/search/visual/Hint';
import SEARCH_SUGGESTIONS_QUERY from 'shared_data/queries/search/suggestions.graphql';

import {
  searchFormCls,
  searchFormRowCls,
  searchClearBtnCls,
  submitBtnCls,
  searchFieldCls,
  searchFieldWrapperCls,
  searchDomainWrapperCls,
  searchTextWrapperCls,
  searchButtonWrapperCls,
  searchSuggestionsCls,
  searchSuggestionsVerticalCls,
  searchSuggestionsHorizontalCls,
  searchSuggestionsHeadingCls,
  searchSuggestionsListCls,
} from 'shared_components/forms/search/Form.less';

type Props = {
  fieldName?: string,
  displayStyle?: 'vertical' | 'horizontal' | 'horizontalInset',
  title?: Node,
  domain?: string,
  suggestionsHeading?: Node,
  suggestionsLimit?: number,
  showDomain?: boolean,
  showSuggestions?: boolean,
  showHint?: Boolean,
  onSubmit: Function,
  parentOnSubmit?: Function,
  onClear: Function,
  onFocus?: Function,
  onChange?: Function,
  initialValue?: string,
  autoFocus: boolean,
  placeholder?: Node | string,
  prefix?: Node | string,
  prefixChild?: Function | null,
  timeoutMillisec?: number,
};

/**
 * SearchComponent
 *
 */
export const SearchComponent = ({
  displayStyle = 'vertical',
  title = null,
  domain = null,
  showDomain = false,
  showSuggestions = false,
  showHint = false,
  suggestionsHeading = null,
  suggestionsLimit = null,
  onSubmit,
  parentOnSubmit,
  onChange,
  onClear,
  onFocus,
  initialValue = null,
  autoFocus = false,
  placeholder = null,
  prefix = null,
  prefixChild = null,
  timeoutMillisec = CONFIG_COMPONENTS.search.timeout,
}: Props): Node => {
  const IS_VERTICAL_STYLE = displayStyle === 'vertical';
  const formRef = useRef(null);
  const formInputRef = useRef(null);
  const suggestionItems = useRef({ items: [], focused: null });
  const { formatMessage } = useIntl();
  const storeCode = useStoreCode();
  const [isFocused, setIsFocused] = useState(autoFocus);
  const [skipSuggestions, setSkipSuggestions] = useState(true);
  const [searchText, setSearchText] = useState(initialValue);
  const [searchDomain, setSearchDomain] = useState(domain || ENUM_SEARCH.domain.ALL);
  const handleSubmit = useCallback(
    (values: Object) => {
      setSearchText(values.q);
      setSkipSuggestions(true);

      if (typeof onSubmit === 'function') {
        onSubmit(values);
      }
    },
    [onSubmit],
  );
  const handleClear = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue({ q: null });
    }

    setSearchText(null);
    if (typeof onClear === 'function') {
      onClear('');
    }
  }, [formRef, onClear]);

  const handleChange = debounce((changedValues) => {
    if (skipSuggestions) {
      setSkipSuggestions(false);
    }

    if (!changedValues) {
      return;
    }

    setSearchText(changedValues.q || null);

    if (changedValues.domain) {
      setSearchDomain(changedValues.domain || null);
    }
  }, timeoutMillisec);

  const handleSuggestionClick = useCallback(
    (value) => () => {
      if (typeof onSubmit === 'function') {
        onSubmit({ q: value, domain: searchDomain });
      }

      if (initialValue === value) {
        // $FlowFixMe
        formRef.current.setFieldsValue({ ...formRef.current.getFieldsValue(), q: value });
      }

      setSearchText(value);
      setSkipSuggestions(true);
    },
    [searchDomain, onSubmit, initialValue],
  );

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue({ q: initialValue });
      autoFocus && formInputRef.current && formInputRef.current.focus();
    }
  }, [initialValue, autoFocus]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(searchText, searchDomain);
    }
  }, [searchText, searchDomain]); // eslint-disable-line

  useEffect(() => {
    if (typeof onFocus === 'function') {
      onFocus(isFocused);
    }
  }, [isFocused, onFocus]);

  function handleKeyPress(event: Object) {
    const totalItems = suggestionItems.current.items.length;
    const { focused } = suggestionItems.current;
    const elements = document.querySelectorAll(`.${searchSuggestionsListCls} li a`);

    if ([38, 40].indexOf(event.keyCode) === -1) {
      return;
    }

    switch (event.keyCode) {
      // ArrowUp
      case 38: {
        event.preventDefault();
        if (!focused || focused === 0) {
          suggestionItems.current.focused = totalItems - 1;
        } else {
          // eslint-disable-next-line
          suggestionItems.current.focused = suggestionItems.current.focused - 1;
        }
        break;
      }
      // ArrowDown
      case 40: {
        event.preventDefault();
        if (focused === totalItems - 1) {
          suggestionItems.current.focused = 0;
        } else if (focused === null) {
          suggestionItems.current.focused = 0;
        } else {
          // eslint-disable-next-line
          suggestionItems.current.focused = suggestionItems.current.focused + 1;
        }
        break;
      }
      default:
        break;
    }

    if (
      suggestionItems.current.focused !== null &&
      elements.item &&
      elements.item(suggestionItems.current.focused)
    ) {
      elements.item(suggestionItems.current.focused).focus();
    }
  }

  useEffect(() => {
    if (IS_VERTICAL_STYLE) {
      document.addEventListener('keydown', handleKeyPress);

      return function cleanup() {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [IS_VERTICAL_STYLE]);

  return (
    <Form
      ref={formRef}
      className={searchFormCls}
      onFinish={handleSubmit}
      initialValues={{ q: searchText, domain: ENUM_SEARCH.domain.ALL }}
      onValuesChange={handleChange}
    >
      {title ? (
        <Heading level="5" tag="h3">
          {title}
        </Heading>
      ) : null}
      <div className={searchFormRowCls}>
        {typeof prefixChild === 'function'
          ? prefixChild({ isFocused, searchText, handleClear, setIsFocused })
          : null}
        <FormItem className={searchFieldWrapperCls} name="q">
          <FormInput
            ref={formInputRef}
            type="search"
            autoComplete="off"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...(prefix || (displayStyle === 'horizontalInset' && !searchText && !isFocused)
              ? { prefix: prefix || <SearchIcon /> }
              : null)}
            suffix={
              searchText ? (
                <Button
                  size="xlarge"
                  icon={<CloseIcon />}
                  className={searchClearBtnCls}
                  type="link"
                  linkType="strong"
                  title={formatMessage({ id: 'forms.searchForm.clear' })}
                  onClick={handleClear}
                />
              ) : (
                (displayStyle === 'horizontalInset' && isFocused ? (
                  <Button
                    size="xlarge"
                    htmlType="submit"
                    icon={<SearchIcon />}
                    className={submitBtnCls}
                    type="link"
                    linkType="strong"
                    title={formatMessage({ id: 'forms.searchForm.submit' })}
                  />
                ) : (
                  <Fragment /> // eslint-disable-line react/jsx-no-useless-fragment
                )) || <Fragment /> // eslint-disable-line react/jsx-no-useless-fragment
              )
            }
            placeholder={placeholder || formatMessage({ id: 'forms.searchForm.placeholder' })}
            className={searchFieldCls}
          />
        </FormItem>
        {showDomain ? (
          <Fragment>
            <span className={searchTextWrapperCls}>in</span>
            <FormItem className={searchDomainWrapperCls} name="domain">
              <FormSelect
                id="search-domain"
                className={null}
                size="large"
                choiceTransitionName={null}
                transitionName={null}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              >
                {Object.keys(ENUM_SEARCH.domain).map((domainKey: string) => {
                  const value = ENUM_SEARCH.domain[domainKey];
                  return (
                    <FormSelectOption key={domainKey} value={value}>
                      {formatMessage({ id: `forms.search.domain.${value}` })}
                    </FormSelectOption>
                  );
                })}
              </FormSelect>
            </FormItem>
          </Fragment>
        ) : null}
        {displayStyle !== 'horizontalInset' ? (
          <FormItem className={searchButtonWrapperCls}>
            <Button
              size="xlarge"
              htmlType="submit"
              icon={<SearchIcon />}
              className={submitBtnCls}
              type="link"
              linkType="strong"
              title={formatMessage({ id: 'forms.searchForm.submit' })}
            />
          </FormItem>
        ) : null}
      </div>
      {showSuggestions && searchText ? (
        <Query
          ssr={false}
          query={SEARCH_SUGGESTIONS_QUERY}
          variables={{ domain: searchDomain, store: storeCode, searchTerm: searchText }}
          skip={
            skipSuggestions ||
            (searchText && searchText.length < CONFIG_COMPONENTS.search.suggestions.minChar) ||
            !storeCode ||
            !searchText ||
            !searchDomain ||
            searchDomain === ENUM_SEARCH.domain.ALL
          }
        >
          {({ data, error }) => {
            const suggestions =
              (data &&
                data.searchSuggestions &&
                data.searchSuggestions.items &&
                ((suggestionsLimit && data.searchSuggestions.items.slice(0, suggestionsLimit)) ||
                  data.searchSuggestions.items)) ||
              null;
            const visualSearchComponent = showHint ? (
              <VisualSearchHint showSuggestions={!!suggestions} onLinkClick={parentOnSubmit} />
            ) : null;
            if (!data || error) {
              return visualSearchComponent;
            }

            if (!suggestions || !suggestions.length) {
              return visualSearchComponent;
            }

            suggestionItems.current.items = suggestions;

            return (
              <Fragment>
                <div
                  className={classNames(searchSuggestionsCls, {
                    [searchSuggestionsVerticalCls]: IS_VERTICAL_STYLE,
                    [searchSuggestionsHorizontalCls]: !IS_VERTICAL_STYLE,
                  })}
                >
                  {suggestionsHeading ? (
                    <Heading level="4" tag="h4" className={searchSuggestionsHeadingCls}>
                      {suggestionsHeading}
                    </Heading>
                  ) : null}
                  {isTouchDevice() || IS_VERTICAL_STYLE ? (
                    <ul className={searchSuggestionsListCls}>
                      {suggestions.map((item) => (
                        <li key={item}>
                          <Button
                            size="small"
                            type="link"
                            linkType="light"
                            title={item}
                            onClick={handleSuggestionClick(item)}
                          >
                            {IS_VERTICAL_STYLE ? (
                              <React.Fragment>
                                <SearchIcon />
                                &nbsp;&nbsp;
                              </React.Fragment>
                            ) : null}
                            <HighlightString haystack={item} text={searchText} />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <ContinuousSlider
                      className={searchSuggestionsListCls}
                      height={48}
                      resetScrollOnUpdate
                      nextArrowText={formatMessage({ id: 'common.next' })}
                      previousArrowText={formatMessage({ id: 'common.previous' })}
                    >
                      {suggestions.map((item) => (
                        <Button
                          key={item}
                          size="small"
                          type="link"
                          linkType="light"
                          title={item}
                          onClick={handleSuggestionClick(item)}
                        >
                          {IS_VERTICAL_STYLE ? (
                            <React.Fragment>
                              <SearchIcon />
                              &nbsp;&nbsp;
                            </React.Fragment>
                          ) : null}
                          <HighlightString haystack={item} text={searchText} />
                        </Button>
                      ))}
                    </ContinuousSlider>
                  )}
                </div>
                {visualSearchComponent}
              </Fragment>
            );
          }}
        </Query>
      ) : (
        (showHint && <VisualSearchHint onLinkClick={parentOnSubmit} />) || null
      )}
    </Form>
  );
};
