// @flow

export const STORAGE_TYPES = { localStorage: 'localStorage', sessionStorage: 'sessionStorage' };

export type StorageTypes = 'localStorage' | 'sessionStorage';

export interface Storage {
  setup(storageType: StorageTypes): void;
  load(key: string): any;
  save(key: string, value: any): boolean;
}
