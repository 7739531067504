// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { cartEmptySidebarCls } from 'shared_components/cart/sections/empty/Empty.less';

/**
 * CartEmptySidebar
 */
export const CartEmptySidebar = () => (
  <div className={cartEmptySidebarCls}>
    <FormattedMessage id="components.cart.sidebarBasketEmptyText">
      {(label: string) => label}
    </FormattedMessage>
  </div>
);
