// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { CartPaymentCardNumber } from '@riseart/cart';
import { cart as ENUM_CART } from 'Enum';
import { CheckoutDetailsRow } from 'shared_components/checkout/sections/info/Details';

type Props = {
  checkoutProps: Object,
  billingData: Object,
};

/**
 * StripePaymentDescription
 *
 * @param {Props} props
 * @returns {Node}
 */
export const StripePaymentDescription = ({ checkoutProps, billingData: data }: Props): Node => {
  const { TYPE_CARD } = ENUM_CART.paymentType;
  const { paymentData: payment, useCredit, total, data: checkoutData } = checkoutProps || {};
  const { type: paymentType, isValid } = payment || {};
  const { userPaymentId } = checkoutData || {};
  const requiresPayment = total > 0;
  const hasCard = userPaymentId || (paymentType === TYPE_CARD && isValid);
  const hasCardData = data.cardType || data.cardNumber || data.cardOwner || data.cardValidThru;
  const cardTranslationKey =
    (useCredit && !requiresPayment && 'useCredits') ||
    (useCredit && hasCard && hasCardData && requiresPayment && 'useCardAndCredits') ||
    (!useCredit && hasCard && hasCardData && requiresPayment && 'useCard') ||
    (useCredit && requiresPayment && 'usePaymentTypeAndCredits') ||
    'usePaymentType';

  return (
    <Fragment>
      {/* Payment type message */}
      <CheckoutDetailsRow>
        <FormattedMessage
          id={`components.checkout.${cardTranslationKey}`}
          values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            paymentType: (
              <FormattedMessage id={`components.checkout.paymentType.${paymentType || 'stripe'}`}>
                {(text: string) => text}
              </FormattedMessage>
            ),
          }}
        >
          {(text: string) => text}
        </FormattedMessage>
      </CheckoutDetailsRow>
      {/* Card specific data */}
      {requiresPayment && hasCardData ? (
        <CheckoutDetailsRow>
          {data.cardType || data.cardNumber ? (
            <div>
              {data.cardType ? (
                <FormattedMessage id={`components.checkout.cards.${data.cardType}`}>
                  {(cardName: string) => `${cardName} `}
                </FormattedMessage>
              ) : null}
              {data.cardNumber ? <CartPaymentCardNumber number={data.cardNumber} /> : null}
            </div>
          ) : (
            <div>{data.cardName}</div>
          )}
          {data.cardOwner ? <div>{data.cardOwner}</div> : null}
          {data.cardValidThru ? (
            <div>
              <FormattedMessage id="components.checkout.cardValidThru">
                {(text: string) => text}
              </FormattedMessage>{' '}
              {data.cardValidThru}
            </div>
          ) : null}
        </CheckoutDetailsRow>
      ) : null}
    </Fragment>
  );
};
