// @flow

import classNames from 'classnames';
import React from 'react';
import { Wrapper } from '@riseart/layout';

import { pageHeadWrapperCls } from 'shared_components/common/sections/PageHead.less';

/**
 * PageHeadWrapper
 *
 * @param {Props} props
 * @returns {React$Element<*>}
 */
export function PageHeadWrapper({ className, children, ...restProps }: Object): React$Element<*> {
  return (
    <Wrapper className={classNames(pageHeadWrapperCls, className)} {...restProps}>
      {children}
    </Wrapper>
  );
}
