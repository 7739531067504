// @flow

import isNaN from 'lodash/isNaN';
import classNames from 'classnames';
import React, { Component } from 'react';
import type { Node } from 'react';
import cardValidator from 'card-validator';
import { CartPaymentCard } from '@riseart/cart';
import { FormItemRenderer } from '@riseart/form';
import { cart as ENUM_CART } from 'Enum';
import { CVCHint } from 'shared_components/checkout/sections/payment/CVCHint';
import { CartCardFormModel } from 'shared_models/forms/checkout/Card';

import {
  paymentCardInputCls,
  paymentCardInputNumberCls,
  paymentCardInputValidThruCls,
  paymentCardInputCvcCls,
  paymentCardFormRowCls,
} from 'shared_components/checkout/sections/payment/Payment.less';

type Props = {
  data: Object,
  form: Object,
  formLayout: string,
  intl: Object,
  onChange: Function,
  onPaymentChange: Function,
};

type State = {
  cardType: ?string,
};

export const SUPPORTED_CARDS = {
  visa: ENUM_CART.cardType.CARD_TYPE_VISA,
  mastercard: ENUM_CART.cardType.CARD_TYPE_MASTERCARD,
  maestro: ENUM_CART.cardType.CARD_TYPE_MAESTRO,
};

export class DatacashForm extends Component<Props, State> {
  formFields: Object = {};

  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    const { data } = this.props;

    this.state = {
      cardType: data.cardNumber ? cardValidator.number(data.cardNumber).card.type : null,
    };

    this.bindMethods();
  }

  /**
   * formatCard
   *
   * @param {string} card
   * @returns {string} formatted card string
   */
  formatCard(card: string): string {
    const cardNumber = card.replace(/\s+/g, '').replace(/[^0-9]/gi, '');

    return cardNumber.split('').reduce((accumulator, digit, key) => {
      return `${accumulator}${key > 0 && key % 4 === 0 ? ' ' : ''}${digit}`;
    }, '');
  }

  /**
   * formatDate
   *
   * @param {string} inputValue
   * @param {string} prevValue
   * @returns {string}
   */
  formatDate(inputValue: string, prevValue: string): string {
    // Remove separator if user deleted the year and what is left is the ' /' at the end
    const removeSeparator =
      inputValue.trim().slice(-2) === ' /' && prevValue.length > inputValue.length;
    const removeSpacer = inputValue && inputValue.length === 4 && inputValue[3] === '/';
    const cardDate = inputValue.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const month = cardDate.substring(0, 2);
    const parsedMonth = parseInt(month, 10);
    const year = cardDate.substring(2, 4);
    const monthLength = month.length;

    // Month has zero value with length >= 2 (e.g. 00, 000, ...) or isNaN
    if ((monthLength >= 2 && parsedMonth < 1) || isNaN(parsedMonth)) {
      return '';
    }

    let correctedMonth = parsedMonth > 0 || monthLength < 2 ? month : '';

    // Correct month if values is not between 1 and 12
    if (
      parsedMonth > 12 ||
      (inputValue.length > 1 && monthLength === 1) ||
      (monthLength === 1 && parsedMonth > 1)
    ) {
      correctedMonth = `0${month.slice(0, 1)}`;
    }

    return `${correctedMonth}${
      year || (correctedMonth && correctedMonth.length === 2 && !removeSpacer && !removeSeparator)
        ? ` / ${year}`
        : ''
    }`;
  }

  /**
   * handleInputChange
   *
   * @param {Object} field
   * @returns {Function}
   */
  handleInputChange: Function;

  handleInputChange(field: Object): Function {
    /**
     * @param {Object} e
     * @returns {string}
     */
    return (e: Object): string => {
      const { value } = e.target || {};

      const prevValues = { ...this.formFields };
      this.formFields[field.name] = value;

      switch (field.name) {
        case 'cardNumber': {
          const { cardType: cardTypeFromState } = this.state;
          const cardNumberValidation = cardValidator.number(value);

          if (cardNumberValidation.card) {
            const cardType = cardNumberValidation.card.type;

            if (
              cardTypeFromState !== cardType &&
              Object.keys(SUPPORTED_CARDS).indexOf(cardType) > -1
            ) {
              this.setState({ cardType });
              this.props.onPaymentChange(cardType ? { cardType } : null);
            }
          } else {
            this.setState({ cardType: null });
            this.props.onPaymentChange(null);
          }

          return this.formatCard(value);
        }
        case 'cardStartDate':
        case 'cardValidThru': {
          return this.formatDate(value, prevValues[field.name]);
        }
        case 'cardSecurity': {
          return value;
        }
        default: {
          return value;
        }
      }
    };
  }

  /**
   * cardCVCRule
   *
   * Additional rule to validate precisely the CVC code on card,
   * if a card is deteccted and a max length of digits for cvc is detected
   * depending on the card number and type
   *
   * @param {Object} form
   * @returns {{ message: String, validator: Function }} validation rule
   */
  cardCVCRule(form: Object): { message: String, validator: Function } {
    return {
      message: 'forms.checkout.validation.notValidCardSecurity',
      validator: (rule, value, cb) => {
        const enteredCardNumber = form && form.getFieldValue('cardNumber');

        if (enteredCardNumber) {
          const cardNumberValidation = cardValidator.number(enteredCardNumber);
          const cvcLength =
            (cardNumberValidation &&
              cardNumberValidation.card &&
              cardNumberValidation.card.code &&
              cardNumberValidation.card.code.size) ||
            3;

          if (!cardValidator.cvv(value, cvcLength).isValid) {
            cb(true);
          } else {
            cb();
          }
        } else {
          cb();
        }
      },
    };
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  /**
   * render
   */
  render(): Node {
    const { data, formLayout, intl, form } = this.props;
    const { cardType } = this.state;
    const [
      cardNumberField,
      cardOwnerField,
      cardValidField,
      cardCvcField,
      cardMaestroStartDate,
      cardMaestroIssueNumber,
    ] = CartCardFormModel.fields;
    return (
      <div>
        <FormItemRenderer
          {...cardNumberField}
          rules={[
            ...cardNumberField.rules,
            {
              message: 'forms.checkout.validation.notSupportedCardNumber',
              validator: (rule, value, cb) => {
                const cardNumberValidation = cardValidator.number(value);
                const cardType =
                  cardNumberValidation &&
                  cardNumberValidation.card &&
                  cardNumberValidation.card.type;
                cb(Object.keys(SUPPORTED_CARDS).indexOf(cardType) === -1 ? true : undefined);
              },
            },
          ]}
          layout={formLayout}
          form={form}
          intl={intl}
          className={classNames(paymentCardInputCls, paymentCardInputNumberCls)}
          getValueFromEvent={this.handleInputChange(cardNumberField)}
          initialValue={data.cardNumber}
          prefix={
            <CartPaymentCard
              type={
                cardType && Object.keys(SUPPORTED_CARDS).indexOf(cardType) > -1 ? cardType : null
              }
              size="small"
            />
          }
        />
        <FormItemRenderer
          {...cardOwnerField}
          layout={formLayout}
          form={form}
          intl={intl}
          initialValue={data.cardOwner}
        />
        <div className={paymentCardFormRowCls}>
          <FormItemRenderer
            {...cardValidField}
            layout={formLayout}
            form={form}
            intl={intl}
            initialValue={data.cardValidThru}
            formItemClassName={classNames(paymentCardInputCls, paymentCardInputValidThruCls)}
            getValueFromEvent={this.handleInputChange(cardValidField)}
          />
          <FormItemRenderer
            {...cardCvcField}
            rules={[...cardCvcField.rules, this.cardCVCRule(form)]}
            layout={formLayout}
            form={form}
            intl={intl}
            initialValue={data.cardSecurity}
            formItemClassName={classNames(paymentCardInputCls, paymentCardInputCvcCls)}
            suffix={<CVCHint />}
          />
        </div>
        {this.state.cardType === ENUM_CART.cardType.CARD_TYPE_MAESTRO ? (
          <div className={paymentCardFormRowCls}>
            <FormItemRenderer
              {...cardMaestroStartDate}
              layout={formLayout}
              placeholder={
                formLayout === 'inline'
                  ? cardMaestroStartDate.label
                  : cardMaestroStartDate.placeholder
              }
              form={form}
              intl={intl}
              initialValue={data.cardStartDate}
              formItemClassName={classNames(paymentCardInputCls, paymentCardInputValidThruCls)}
              getValueFromEvent={this.handleInputChange(cardMaestroStartDate)}
            />
            <FormItemRenderer
              {...cardMaestroIssueNumber}
              layout={formLayout}
              placeholder={formLayout === 'inline' ? cardMaestroIssueNumber.label : null}
              form={form}
              intl={intl}
              initialValue={data.cardIssueNumber}
              formItemClassName={classNames(paymentCardInputCls, paymentCardInputCvcCls)}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
