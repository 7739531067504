// @flow

import classNames from 'classnames';
import React from 'react';
import type { Node } from 'react';
import { gridNoresultsCls } from 'shared_components/arts/containers/grid/NoResults.less';

type Props = {
  className?: string | null,
  children?: Node,
};

/**
 * GridNoResults
 *
 * @param {Props} props
 * @returns {Node}
 */
export function GridNoResults({ children, className = null }: Props): Node {
  return <div className={classNames(gridNoresultsCls, className)}>{children}</div>;
}
