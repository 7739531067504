// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CheckoutDetails,
  CheckoutDetailsRow,
} from 'shared_components/checkout/sections/info/Details';

type Props = { order: Object };

/**
 * PaymentDetailCustomerCredit
 *
 * @param {Props} props
 * @returns {Node}
 */
export function PaymentDetailCustomerCredit({ order }: Props): Node {
  return (
    <CheckoutDetails>
      <CheckoutDetailsRow>
        {order.creditsAmount ? (
          <div>
            <FormattedMessage id="components.checkout.usedCredits">
              {(text: string) => text}
            </FormattedMessage>
          </div>
        ) : null}
      </CheckoutDetailsRow>
    </CheckoutDetails>
  );
}
