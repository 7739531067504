// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import { Heading, Button, MediaQuery } from '@riseart/common';
import { Tile } from '@riseart/layout';
import {
  CartSidebar as CommonCartSidebar,
  CartItem,
  CartSummaryShipping,
  CartSummaryWrapper,
  CartSummaryList,
  CartSummaryListRow,
  CartSummaryListRowLine,
  CartSummarySubtotal,
} from '@riseart/cart';
import { cart as ENUM_CART } from 'Enum';
import { payment as CONFIG_PAYMENT } from 'Config';
import { guiUpdate } from 'shared_services/redux/actions/application/gui';
import { withGUIState } from 'shared_hocs/gui/withGuiState';
import { GUI_PROPERTIES } from 'shared_models/Gui';
import { WithCheckoutUrl } from 'shared_hocs/cart/WithCheckoutUrl';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { HOCCart } from 'shared_hocs/cart/Cart';
import { WithOffsetHeight } from 'shared_hocs/gui/withOffsetHeight';
import { WithCartLoading } from 'shared_hocs/cart/Loading';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { formatCurrency, calculateOverlayOffset } from 'shared_services/riseart/utils/Utils';
import { HOCCartErrors } from 'shared_hocs/cart/Errors';
import { HOCCartActions } from 'shared_hocs/cart/Actions';
import { HOCCartSummary } from 'shared_hocs/cart/Summary';
import { HOCCartItems } from 'shared_hocs/cart/Items';
import { CartEmptySidebar } from 'shared_components/cart/sections/empty/Sidebar';
import { SummaryDiscountAndCredits } from 'shared_components/cart/sections/summary/DiscountAndCredits';
import { CartPaymentInfoSection } from 'shared_components/cart/sections/PaymentInfo';
import { PaymentMethodFactory } from 'shared_hocs/cart/payments/Factory';
import UPDATE_CART_SHIPPING_MUTATION from 'shared_data/queries/cart/updateShipping.graphql';

import { raScreenSm } from '@riseart/antd-provider/dist/website/variables.less';

const CartItems = HOCCartErrors(HOCCartItems(CartItem));
const CartShippingSummary = HOCCartErrors(HOCCartActions(HOCCartSummary(CartSummaryShipping)));
const CartDiscountAndCredits = HOCCartSummary(SummaryDiscountAndCredits);

type Props = {
  onClose: Function,
  isVisible: Boolean,
  openTrigger: ?string,
  cart: Object,
  cartItems: Array<Object>,
  cartType: string,
  storeCode: string,
  visitorId: string,
  handleLoading: Function,
  queryRequest: Object,
  loading: boolean,
};

/**
 * CartSidebarContainer
 *
 * @param {Props} props
 */
function CartSidebarContainer({
  onClose,
  isVisible,
  cart,
  cartItems = [],
  cartType,
  storeCode,
  visitorId,
  loading: disabled,
  handleLoading,
  queryRequest,
}: Props) {
  const locale = useLocale();
  const [updateCartShipping] = useMutation(UPDATE_CART_SHIPPING_MUTATION);
  const PaymentMethodAdapter = PaymentMethodFactory.getClass(
    CONFIG_PAYMENT[cartType].defaultMethod,
    cart.paymentMethod,
  );

  return (
    <MediaQuery minWidth={raScreenSm}>
      {(isScreenTablet) => (
        <WithOffsetHeight>
          {({ notificationsHeight, applicationsHeight }) => {
            const offset = isScreenTablet
              ? calculateOverlayOffset(notificationsHeight, applicationsHeight)
              : notificationsHeight;

            const countCartItems = (cartItems && cartItems.length) || 0;

            return (
              <CommonCartSidebar
                onClose={onClose}
                isVisible={isVisible}
                showFooter={!!countCartItems}
                topOffset={offset}
                header={
                  <FormattedMessage id="components.cart.myBasket">
                    {(myBasketLabel: string) => (
                      <Heading tag="h3" level="3">
                        {myBasketLabel}
                        {!queryRequest.loading ? (
                          <FormattedMessage
                            id="components.cart.countItems"
                            values={{ count: countCartItems }}
                          >
                            {(countItemsLabel: string) => <span> {countItemsLabel}</span>}
                          </FormattedMessage>
                        ) : null}
                      </Heading>
                    )}
                  </FormattedMessage>
                }
                summary={
                  countCartItems ? (
                    <CartSummaryWrapper>
                      <CartSummaryList>
                        <CartSummaryListRow>
                          <CartSummaryListRowLine
                            label={
                              <FormattedMessage id="components.cart.subtotal">
                                {(subtotalLabel: string) => subtotalLabel}
                              </FormattedMessage>
                            }
                          >
                            {cart && cart.subtotalAmount >= 0
                              ? formatCurrency(cart.subtotalAmount, storeCode, undefined, {
                                  locale: locale.name,
                                })
                              : null}
                          </CartSummaryListRowLine>
                        </CartSummaryListRow>
                        <CartSummaryListRow>
                          <CartShippingSummary
                            {...cart}
                            updateCartShippingMutation={updateCartShipping}
                            disabled={disabled}
                            onLoading={handleLoading}
                            cartType={cartType}
                            visitorId={visitorId}
                            canEditShipping
                          />
                        </CartSummaryListRow>
                        <CartDiscountAndCredits
                          {...cart}
                          cartType={cartType}
                          visitorId={visitorId}
                        />
                      </CartSummaryList>
                      <CartSummarySubtotal
                        label={
                          <FormattedMessage id="components.cart.total">
                            {(totalLabel: string) => totalLabel}
                          </FormattedMessage>
                        }
                      >
                        {formatCurrency(cart.totalAmount, storeCode, undefined, {
                          locale: locale && locale.name,
                        })}
                      </CartSummarySubtotal>
                    </CartSummaryWrapper>
                  ) : null
                }
                footer={
                  countCartItems ? (
                    <React.Fragment>
                      <Button isAnchor to={UrlAssembler.cart('cart')} type="secondary">
                        <FormattedMessage id="components.cart.viewBasket">
                          {(btnLabel: string) => btnLabel}
                        </FormattedMessage>
                      </Button>
                      <WithCheckoutUrl isRental={cart && cart.rental}>
                        {(checkoutUrl) => (
                          <Button isAnchor to={checkoutUrl}>
                            <FormattedMessage id="components.cart.checkout">
                              {(btnLabel: string) => btnLabel}
                            </FormattedMessage>
                          </Button>
                        )}
                      </WithCheckoutUrl>
                    </React.Fragment>
                  ) : null
                }
                subfooter={
                  countCartItems && PaymentMethodAdapter ? (
                    <CartPaymentInfoSection
                      cards={PaymentMethodAdapter.displaySupportedPayments()}
                    />
                  ) : null
                }
              >
                <WithCartLoading disabled={disabled} onLoading={handleLoading}>
                  {({ disabled, loading, onLoading }) => (
                    <Tile
                      size="small"
                      disabled={disabled}
                      loading={loading || queryRequest.loading}
                    >
                      {queryRequest.loading || countCartItems ? (
                        <CartItems
                          displayStyle="sidebar"
                          lazyloadImages={false}
                          items={cartItems}
                          storeCode={storeCode}
                          cartType={cartType}
                          visitorId={visitorId}
                          onLoading={onLoading}
                        />
                      ) : (
                        <CartEmptySidebar />
                      )}
                    </Tile>
                  )}
                </WithCartLoading>
              </CommonCartSidebar>
            );
          }}
        </WithOffsetHeight>
      )}
    </MediaQuery>
  );
}

const CartSidebarWithData = HOCCart(CartSidebarContainer);

/**
 * CartSidebarWrapper
 */
class CartSidebarWrapper extends Component<Props> {
  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    this.props.onClose();
  }

  /**
   * render
   */
  render() {
    return this.props.isVisible ? (
      <CartSidebarWithData
        {...this.props}
        forceRefetch={this.props.openTrigger === ENUM_CART.sidebar.trigger.TRIGGER_SECONDARY_MENU}
      />
    ) : null;
  }
}

/**
 * mapStateToProps
 *
 * @param {Object} state
 * @returns {Object} mapped state to props
 */
const mapStateToProps = (state: Object): Object => {
  const { opened, trigger } = state[GUI_PROPERTIES.CART_SIDEBAR] || {};

  return { isVisible: opened, openTrigger: trigger };
};

/**
 * mapDispatchToProps
 *
 * @param {Function} dispatch
 * @returns {Object} mapped dispatch functions to props
 */
const mapDispatchToProps = (dispatch: Function): Object => ({
  onClose: () =>
    dispatch(
      guiUpdate(GUI_PROPERTIES.CART_SIDEBAR, {
        opened: false,
        trigger: ENUM_CART.sidebar.trigger.TRIGGER_SIDEBAR,
      }),
    ),
});

export const CartSidebar = withGUIState(mapStateToProps, mapDispatchToProps)(CartSidebarWrapper);
