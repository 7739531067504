// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '@riseart/common';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';

// Translations used for frame component from monorepo
export const TRANSLATIONS: Object = {
  shipsFramed: <FormattedMessage id="components.art.shipsFramed" />,
  shipsFramedTooltipHeading: <FormattedMessage id="components.art.shipsFramedTooltipHeading" />,
  shipsFramedTooltipDescription: (
    <FormattedMessage id="components.art.shipsFramedTooltipDescription" />
  ),
  readyToHang: <FormattedMessage id="components.art.shipsReadyToHang" />,
  readyToHangTooltipHeading: (
    <FormattedMessage id="components.art.shipsReadyToHangTooltipHeading" />
  ),
  readyToHangTooltipDescription: (
    <FormattedMessage id="components.art.shipsReadyToHangTooltipDescription" />
  ),
  shipsFramedAndReadyToHang: <FormattedMessage id="components.art.shipsFramedAndHang" />,
  addFrame: <FormattedMessage id="components.art.addFrame" />,
  framed: <FormattedMessage id="components.art.framed" />,
  noFrame: <FormattedMessage id="components.art.noFrame" />,
  unframed: <FormattedMessage id="components.art.unframed" />,
  frameLabel: (label: string, isReadOnly: boolean = false) =>
    isReadOnly ? (
      label
    ) : (
      <FormattedMessage id="components.art.addFrame">
        {(addFrameLabel: string) => (
          <Link
            external
            href={LocalizedConfig.get('externalUrls.framingHelp')}
            title={addFrameLabel}
            target="_blank"
            type="light"
          >
            {label}
          </Link>
        )}
      </FormattedMessage>
    ),
};
