// @flow
import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Hint, Paragraph } from '@riseart/common';

import { cvcIconCls } from 'shared_components/checkout/sections/payment/CVCHint.less';

export const CVCHint = (): Node => (
  <Hint
    type="hint"
    padded
    wrapperTag="span"
    showOnClick
    placement="NE"
    text={
      <React.Fragment>
        <FormattedMessage tagName="strong" id="components.checkout.securityCodeHintTitle" />
        <FormattedMessage id="components.checkout.securityCodeHintDescription">
          {(hintDescription: string) => <Paragraph>{hintDescription}</Paragraph>}
        </FormattedMessage>
      </React.Fragment>
    }
  >
    <span className={cvcIconCls} />
  </Hint>
);
