// @flow

import get from 'lodash/get';
import { v4 } from 'uuid';
import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { ArtistCard } from '@riseart/artist';
import { artDirection as CONFIG_ART_DIRECTION } from 'Config';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';
import { extractImageFileFromData } from 'shared_services/riseart/utils/ImageUtils';
import { generatePicturePropsForCDN } from 'shared_components/common/artdirection/picture/Picture';
import { ART_DIRECTION_SIZES } from 'shared_models/ArtDirectionSizes';
import { ListArtQuery as ListQuery } from 'shared_data/providers/queries/ListArtQuery';
import { HorizontalTrackedSlider } from 'shared_components/common/slider/Tracked';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import FILTER_ART_QUERY from 'shared_data/queries/filter/art.graphql';

import { artistArtSliderCls } from 'shared_components/artist/art/Slider.less';

const SEARCH_ARTIST_ART_PRELOADER_ID = v4();

type Props = {
  items?: Array<Object>,
  placeholder?: boolean,
  columnCount?: string,
  displayStyle?: string,
  tracking?: Object | null,
};

/**
 * ArtistArtSlider
 *
 * @param {Props} props
 * @returns {Node}
 */
export function ArtistArtSlider({
  items,
  placeholder = false,
  columnCount = 4,
  displayStyle = 1,
  tracking = null,
}: Props): Node {
  const storeCode = useStoreCode();

  return (
    <ListQuery
      query={FILTER_ART_QUERY}
      variables={{
        ...(items
          ? {
              artistIds: items.reduce(
                (accumulator, item, index) => `${accumulator}${index ? ',' : ''}${item.id}`,
                '',
              ),
              onePerArtist: true,
            }
          : {}),
        inputArtFilter: { store: storeCode },
      }}
      skip={!storeCode}
      dataHandler={(data) => data && data.filterArt}
      preloadSubscriberId={SEARCH_ARTIST_ART_PRELOADER_ID}
      render={({ data }) => {
        const artistArtData = (data && data.filterArt && data.filterArt.items) || null;

        return (
          <HorizontalTrackedSlider
            columnCount={columnCount}
            items={items}
            placeholder={placeholder}
            itemPlaceholderRenderer={(item, idx) => (
              <ArtistCard key={idx} placeholder displayStyle={displayStyle} />
            )}
            itemRenderer={({ id, alias, name, livesCountry, mediums, images }) => {
              const data =
                artistArtData &&
                artistArtData.find(({ artistId }) => parseInt(artistId, 10) === parseInt(id, 10));
              const artImage =
                data &&
                data.images &&
                extractImageFileFromData.artImage(data.images, 'TYPE_FLAT_SQUARE', true);
              const pictureProps = artImage
                ? {
                    lazyload: false,
                    image: artImage,
                    ...generatePicturePropsForCDN({
                      image: artImage,
                      sizesList: get(ART_DIRECTION_SIZES, 'artist.listArt'),
                      artDirectionConfig: CONFIG_ART_DIRECTION.art.main,
                    }),
                  }
                : null;

              return (
                <ArtistCard
                  key={id}
                  displayStyle={displayStyle}
                  name={name}
                  url={UrlAssembler.artistProfile(id, alias)}
                  showImagePlaceholder
                  pictureProps={pictureProps}
                  infoItems={[
                    ...(livesCountry
                      ? [
                          <FormattedMessage id={`countries.${livesCountry.toUpperCase()}`}>
                            {(label: string) => label}
                          </FormattedMessage>,
                        ]
                      : []),
                    ...(mediums && mediums[0]
                      ? [
                          <FormattedMessage id={`filters.artist.singular.medium.${mediums[0]}`}>
                            {(label: string) => label}
                          </FormattedMessage>,
                        ]
                      : []),
                  ]}
                  images={images}
                  artDirectionSizeType="avatar.small"
                  artDirectionConfigKey="common.avatar"
                />
              );
            }}
            tracking={tracking}
            sliderProps={{ className: artistArtSliderCls }}
          />
        );
      }}
    />
  );
}
