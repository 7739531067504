// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormCheckbox } from '@riseart/form';
import { Heading, Paragraph, Button, Link } from '@riseart/common';
import { cart as ENUM_CART } from 'Enum';
import { application as CONFIG_APP } from 'Config';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { formatCurrency } from 'shared_services/riseart/utils/Utils';
import { useLocale } from 'shared_services/redux/hooks/useLocale';

import { cartCreditSectionCls } from 'shared_components/cart/sections/credit/Credit.less';

type Props = {
  cartType: string,
  customerCredit: number,
  useCredit: boolean,
  canAccessCredit: boolean,
  canAccessGiftCard: Boolean,
  storeCode: string,
  redeemCardHandler: Function,
  handleUseCredit: Function,
};

/**
 * CartCreditSection
 */
export const CartCreditSection = ({
  cartType,
  canAccessCredit,
  canAccessGiftCard,
  customerCredit,
  useCredit = false,
  storeCode,
  redeemCardHandler,
  handleUseCredit,
}: Props): Node => {
  const isRent = cartType === ENUM_CART.type.TYPE_RENT;
  const locale = useLocale();

  return (
    <div className={cartCreditSectionCls}>
      <div>
        <Heading tag="h3" level="3">
          <FormattedMessage id="components.cart.yourCredit">
            {(label: string) => (
              <React.Fragment>
                <span>{label}</span>
                {customerCredit > 0 ? (
                  <span>
                    {formatCurrency(customerCredit, storeCode, undefined, {
                      locale: locale && locale.name,
                    })}
                  </span>
                ) : null}
              </React.Fragment>
            )}
          </FormattedMessage>
        </Heading>
        <Paragraph>
          {canAccessCredit && customerCredit > 0 ? (
            <FormattedMessage id="components.cart.useCredit">
              {(label: string) => (
                <FormCheckbox name="useCredit" checked={useCredit} onChange={handleUseCredit}>
                  {label}
                </FormCheckbox>
              )}
            </FormattedMessage>
          ) : null}
          {canAccessCredit && customerCredit <= 0 ? (
            <FormattedMessage id="components.cart.noVouchers">
              {(label: string) => label}
            </FormattedMessage>
          ) : null}
          {!canAccessCredit ? (
            <FormattedMessage id="components.cart.signInCredit1">
              {(label1: string) => (
                <FormattedMessage id="components.cart.signInCredit2">
                  {(signUpLabel: string) => (
                    <FormattedMessage id="components.cart.signInCredit3">
                      {(label3: string) => (
                        <React.Fragment>
                          {label1}
                          <Link
                            to={UrlAssembler.byRouteKey('register', {
                              search: {
                                role: CONFIG_APP.acl.resourcePermissions.cart.credit,
                                forward: UrlAssembler.cart(isRent ? 'rent' : 'cart', {
                                  hash: 'credit',
                                }),
                              },
                            })}
                            title={signUpLabel}
                          >
                            {signUpLabel}
                          </Link>
                          {label3}
                        </React.Fragment>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          ) : null}
        </Paragraph>
      </div>
      <div>
        <Heading tag="h3" level="3">
          <FormattedMessage id="components.cart.gotGiftCart">
            {(label: string) => label}
          </FormattedMessage>
        </Heading>
        <Paragraph>
          {canAccessGiftCard ? (
            <FormattedMessage id="components.cart.redeemCredit1">
              {(label1: string) => (
                <FormattedMessage id="components.cart.redeemCredit2">
                  {(label2: string) => (
                    <FormattedMessage id="components.cart.redeemCredit3">
                      {(label3: string) => (
                        <React.Fragment>
                          {label1}
                          <Button type="link" onClick={redeemCardHandler}>
                            {label2}
                          </Button>
                          {label3}
                        </React.Fragment>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          ) : (
            <FormattedMessage id="components.cart.signInRedeem1">
              {(label1: string) => (
                <FormattedMessage id="components.cart.signInRedeem2">
                  {(labelLink: string) => (
                    <FormattedMessage id="components.cart.signInRedeem3">
                      {(label3: string) => (
                        <React.Fragment>
                          {label1}

                          <Link
                            to={UrlAssembler.byRouteKey('login', {
                              search: {
                                role: CONFIG_APP.acl.resourcePermissions.cart.giftCard,
                                forward: UrlAssembler.cart(isRent ? 'rent' : 'cart', {
                                  hash: 'giftCard',
                                }),
                              },
                            })}
                            title={labelLink}
                          >
                            {labelLink}
                          </Link>
                          {label3}
                        </React.Fragment>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          )}
        </Paragraph>
      </div>
    </div>
  );
};
