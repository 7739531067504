// @flow

import React, { useCallback } from 'react';
import type { Node } from 'react';
import { useDispatch } from 'react-redux';
import { PaymentElement } from '@stripe/react-stripe-js';
import { cart as ENUM_CART } from 'Enum';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';
import { ErrorService } from 'shared_services/riseart/errors/ErrorService';

type Props = {
  data: Object,
  formLayout: string,
  options?: Object,
  intl: Object,
  onPaymentChange: Function,
};

export const STRIPE_TO_API_CARD_MAPPER: Object = {
  amex: ENUM_CART.cardType.CARD_TYPE_AMEX,
  diners: ENUM_CART.cardType.CARD_TYPE_DINERS,
  discover: ENUM_CART.cardType.CARD_TYPE_DISCOVER,
  jcb: ENUM_CART.cardType.CARD_TYPE_JCB,
  mastercard: ENUM_CART.cardType.CARD_TYPE_MASTERCARD,
  unionpay: ENUM_CART.cardType.CARD_TYPE_UNIONPAY,
  visa: ENUM_CART.cardType.CARD_TYPE_VISA,
  unknown: ENUM_CART.cardType.CARD_TYPE_UNKNOWN,
};

export const SUPPORTED_CARDS: Object = {
  [ENUM_CART.cardType.CARD_TYPE_VISA]: ENUM_CART.cardType.CARD_TYPE_VISA,
  [ENUM_CART.cardType.CARD_TYPE_MASTERCARD]: ENUM_CART.cardType.CARD_TYPE_MASTERCARD,
  [ENUM_CART.cardType.CARD_TYPE_AMEX]: ENUM_CART.cardType.CARD_TYPE_AMEX,
};

export const SUPPORTED_PAYMENT_TYPES: Object = {
  [ENUM_CART.paymentType.TYPE_CARD]: ENUM_CART.paymentType.TYPE_CARD,
  [ENUM_CART.paymentType.TYPE_PAYPAL]: ENUM_CART.paymentType.TYPE_PAYPAL,
  [ENUM_CART.paymentType.TYPE_KLARNA]: ENUM_CART.paymentType.TYPE_KLARNA,
};

export const DISPLAY_SUPPORTED_PAYMENTS: Object = [
  SUPPORTED_CARDS[ENUM_CART.cardType.CARD_TYPE_VISA],
  SUPPORTED_CARDS[ENUM_CART.cardType.CARD_TYPE_MASTERCARD],
  SUPPORTED_CARDS[ENUM_CART.cardType.CARD_TYPE_AMEX],
  SUPPORTED_PAYMENT_TYPES[ENUM_CART.paymentType.TYPE_PAYPAL],
  SUPPORTED_PAYMENT_TYPES[ENUM_CART.paymentType.TYPE_KLARNA],
];

/**
 * StripeForm
 *
 * @param {*} param0
 * @returns
 */
export const StripeForm = ({ onPaymentChange, data, options = {} }: Props): Node => {
  const dispatch = useDispatch();
  const handlePaymenChange = (event) => onPaymentChange(event.value, event.complete);

  const handleError = useCallback(
    (paymentResponse) => {
      if (paymentResponse && paymentResponse.error) {
        dispatch(
          errorAdd(
            ErrorService.mapNotification({
              ...paymentResponse.error,
              detail: paymentResponse.error.message,
            }),
          ),
        );
      }
    },
    [dispatch],
  );

  return (
    <PaymentElement
      onChange={handlePaymenChange}
      onLoadError={handleError}
      options={{
        defaultValues: {
          billingDetails: {
            name: [data.firstname, data.lastname].filter((item) => item).join(' '),
            email: data.email,
          },
        },
        fields: {
          billingDetails: {
            name: 'auto',
            email: 'auto',
            phone: 'never',
            address: {
              line1: 'never',
              line2: 'auto',
              city: 'never',
              state: 'auto',
              country: 'never',
              postalCode: 'never',
            },
          },
        },
        ...options,
      }}
    />
  );
};
