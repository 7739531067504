// @flow

import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import type { Node } from 'react';

import {
  countdownCls,
  countdownVariantDefaultCls,
  countdownVariantOutlinedCls,
  countdownSectionCls,
  countdownLabelCls,
  countdownDigitCls,
  countdownSeparatorCls,
} from 'shared_components/common/countdown/Countdown.less';

type Props = {
  variant?: 'default' | 'outlined',
  countdownDate: string | Object,
  labels: {
    days: Node,
    hours: Node,
    minutes: Node,
    seconds: Node,
  },
};

/**
 * calcTimeLeft
 *
 * @param {string | Object} date
 */
const calcTimeLeft = (date: string | Object): Object => {
  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  const difference = new Date(date).getTime() - new Date().getTime();

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  if (timeLeft.days < 10) {
    timeLeft.days = `0${timeLeft.days}`;
  }

  if (timeLeft.hours < 10) {
    timeLeft.hours = `0${timeLeft.hours}`;
  }

  if (timeLeft.minutes < 10) {
    timeLeft.minutes = `0${timeLeft.minutes}`;
  }

  if (timeLeft.seconds < 10) {
    timeLeft.seconds = `0${timeLeft.seconds}`;
  }

  return timeLeft;
};

/**
 * Countdown
 *
 * @param {props} Props
 * @returns {Node}
 */
export const Countdown = ({ variant = 'default', labels = {}, countdownDate }: Props): Node => {
  const [counter, setCounter] = useState(calcTimeLeft(countdownDate));

  useEffect(() => {
    // If countdown is zero, then stop setTimeout from calling
    if (
      counter.days === '00' &&
      counter.hours === '00' &&
      counter.minutes === '00' &&
      counter.seconds === '00'
    ) {
      return;
    }

    // Call next time calculation after 1000 ms
    const timeoutId = setTimeout(() => {
      setCounter(calcTimeLeft(countdownDate));
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  });

  const labelsComponents = {
    days: <span className={countdownLabelCls}>{labels.days}</span>,
    hours: <span className={countdownLabelCls}>{labels.hours}</span>,
    minutes: <span className={countdownLabelCls}>{labels.minutes}</span>,
    seconds: <span className={countdownLabelCls}>{labels.seconds}</span>,
  };
  const isDefaultVariant = variant === 'default' || !variant;

  return (
    <div
      className={classNames(countdownCls, {
        [countdownVariantDefaultCls]: isDefaultVariant,
        [countdownVariantOutlinedCls]: variant === 'outlined',
      })}
    >
      <span className={countdownSectionCls}>
        {isDefaultVariant ? labelsComponents.days : null}
        <span className={countdownDigitCls}>{counter.days}</span>
        {!isDefaultVariant ? labelsComponents.days : null}
      </span>
      <span className={countdownSeparatorCls}>:</span>
      <span className={countdownSectionCls}>
        {isDefaultVariant ? labelsComponents.hours : null}
        <span className={countdownDigitCls}>{counter.hours}</span>
        {!isDefaultVariant ? labelsComponents.hours : null}
      </span>
      <span className={countdownSeparatorCls}>:</span>
      <span className={countdownSectionCls}>
        {isDefaultVariant ? labelsComponents.minutes : null}
        <span className={countdownDigitCls}>{counter.minutes}</span>
        {!isDefaultVariant ? labelsComponents.minutes : null}
      </span>
      <span className={countdownSeparatorCls}>:</span>
      <span className={countdownSectionCls}>
        {isDefaultVariant ? labelsComponents.seconds : null}
        <span className={countdownDigitCls}>{counter.seconds}</span>
        {!isDefaultVariant ? labelsComponents.seconds : null}
      </span>
    </div>
  );
};
