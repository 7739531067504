// @flow

import React from 'react';
import type { Node } from 'react';
import { CartSummaryListRow, CartSummaryListRowLine } from '@riseart/cart';

type Props = {
  hasDiscount: boolean,
  isCreditApplied: boolean,
  creditsAmount: number,
  discountAmount: number,
  currencyFormatter: Function,
  translations: Object,
};

/**
 * SummaryDiscountAndCredits
 *
 * @param {Props} props
 * @returns {Node}
 */
export const SummaryDiscountAndCredits = ({
  hasDiscount,
  isCreditApplied,
  creditsAmount,
  discountAmount,
  currencyFormatter,
  translations,
}: Props): Node => (
  <React.Fragment>
    {hasDiscount ? (
      <CartSummaryListRow>
        <CartSummaryListRowLine label={translations.discount}>
          {currencyFormatter(discountAmount)}
        </CartSummaryListRowLine>
      </CartSummaryListRow>
    ) : null}
    {isCreditApplied && creditsAmount !== null && creditsAmount !== undefined ? (
      <CartSummaryListRow>
        <CartSummaryListRowLine label={translations.credit}>
          {currencyFormatter(creditsAmount)}
        </CartSummaryListRowLine>
      </CartSummaryListRow>
    ) : null}
  </React.Fragment>
);
