// @flow

import React from 'react';
import type { Node } from 'react';
import { MenuHorizontal } from '@riseart/layout';
import { I18nComponentsLoader } from 'shared_components/i18n/ComponentsLoader';

export type MenuProps = {
  theme?: 'white' | 'black',
  active?: boolean,
  onActiveItem?: Function,
  mobile?: boolean,
  state: Object,
  showRecentWidgetOnPage?: boolean,
};

/**
 * MainMenu
 *
 * @param {Props} props
 * @returns {Node}
 */
export function MainMenu({ theme, active, state, onActiveItem }: MenuProps): Node {
  return (
    <I18nComponentsLoader dataKey="settings">
      {({ mainNavigation }) => {
        if (!mainNavigation || !mainNavigation.length) {
          return null;
        }

        return (
          <MenuHorizontal
            state={state}
            items={mainNavigation}
            theme={theme}
            active={active}
            onActiveItem={onActiveItem}
          />
        );
      }}
    </I18nComponentsLoader>
  );
}
