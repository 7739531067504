// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { CartPaymentCardNumber } from '@riseart/cart';
import { CheckoutDetailsRow } from 'shared_components/checkout/sections/info/Details';

type Props = {
  checkoutProps: Object,
  billingData: Object,
};

/**
 * DatacashPaymentDescription
 *
 * @param {Props} props
 * @returns {Node}
 */
export const DatacashPaymentDescription = ({ checkoutProps, billingData: data }: Props): Node => {
  const { useCredit, total } = checkoutProps;
  const { cardNumber, userPaymentId } = checkoutProps.data || {};
  const hasSelectedCard = cardNumber || userPaymentId;
  const requiresPayment = total > 0;
  const titleTranslationKey =
    (useCredit && !hasSelectedCard && 'useCredits') ||
    (useCredit && hasSelectedCard && 'useCardAndCredits') ||
    (!useCredit && hasSelectedCard && 'useCard') ||
    null;

  return (
    <Fragment>
      <CheckoutDetailsRow>
        {titleTranslationKey ? (
          <FormattedMessage
            id={`components.checkout.${titleTranslationKey}`}
            values={{ strong: (chunks) => <strong>{chunks}</strong> }}
          >
            {(text: string) => text}
          </FormattedMessage>
        ) : null}
      </CheckoutDetailsRow>
      {requiresPayment ? (
        <CheckoutDetailsRow>
          <div>
            {data.cardType || data.cardNumber ? (
              <React.Fragment>
                {data.cardType ? (
                  <React.Fragment>
                    <FormattedMessage id={`components.checkout.cards.${data.cardType}`}>
                      {(cardName: string) => cardName}
                    </FormattedMessage>{' '}
                    {/* eslint-disable-line */}
                  </React.Fragment>
                ) : null}
                {data.cardNumber ? <CartPaymentCardNumber number={data.cardNumber} /> : null}
              </React.Fragment>
            ) : (
              data.cardName
            )}
          </div>
          {data.cardOwner ? <div>{data.cardOwner}</div> : null}
          {data.cardValidThru ? (
            <div>
              <FormattedMessage id="components.checkout.cardValidThru">
                {(text: string) => text}
              </FormattedMessage>{' '}
              {data.cardValidThru}
            </div>
          ) : null}
        </CheckoutDetailsRow>
      ) : null}
    </Fragment>
  );
};
