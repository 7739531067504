// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { HorizontalSlider } from '@riseart/slider';
import { useListTracking } from 'shared_services/redux/hooks/useListTracking';

type Props = {
  columnCount?: number | string,
  displayStyle?: number | string,
  items?: Array<Object>,
  sliderProps?: Object,
  placeholder?: boolean,
  tracking?: Object,
  itemRenderer: Function,
  itemPlaceholderRenderer?: Function,
};

/**
 * HorizontalTrackedSlider
 *
 * @param {Props} props
 */
export const HorizontalTrackedSlider = ({
  columnCount = '5',
  displayStyle = '1',
  items = [],
  sliderProps = {},
  placeholder = false,
  tracking,
  itemRenderer,
  itemPlaceholderRenderer,
}: Props): Node => {
  const { handleItemClick, handleVisibilityChange } = useListTracking(
    items,
    !placeholder && tracking,
  );
  const requiresVisibilityTracking =
    tracking && tracking.enabled && tracking.enableVisibility && !tracking.enableInitial;
  const VisibilityComponent = requiresVisibilityTracking ? VisibilitySensor : Fragment;
  const visibilityComponentProps = requiresVisibilityTracking
    ? {
        onChange: handleVisibilityChange,
        partialVisibility: true,
        ...((tracking && tracking.visibilityProps) || null),
      }
    : {};

  return (
    // $FlowFixMe
    <VisibilityComponent {...visibilityComponentProps}>
      <HorizontalSlider
        columnCount={columnCount}
        displayStyle={displayStyle}
        {...sliderProps}
        placeholder={placeholder}
      >
        {placeholder && typeof itemPlaceholderRenderer === 'function'
          ? // Multiply columnCount, so that it renderes by default more than one slide in placeholder mode
            Array.apply(0, Array(parseInt(columnCount, 10) * 2)).map(itemPlaceholderRenderer)
          : items.map((item, index) => itemRenderer(item, handleItemClick, index))}
      </HorizontalSlider>
    </VisibilityComponent>
  );
};
