// @flow

import React from 'react';
import { Link } from '@riseart/common';
import { FormattedMessage } from 'react-intl';

import { ownArtCls } from 'shared_components/common/ownart/Link.less';

/**
 * OwnArtLink
 */
export const OwnArtLink = () => (
  <Link
    type="light"
    external
    obfuscate
    href="https://www.ownart.org.uk/"
    target="_blank"
    className={ownArtCls}
  >
    <FormattedMessage id="layouts.footer.zeroFinance" tagName="span" />
  </Link>
);
