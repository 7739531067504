// @flow

import React from 'react';
import type { Node } from 'react';

type Props = {
  children: Function,
};

/**
 * DatacashProvider
 *
 * @param {Props} props
 * @returns {Node}
 */
export const DatacashProvider = ({ children }: Props): Node => (
  <React.Fragment>{children({})}</React.Fragment>
);
