// @flow

import React from 'react';
import type { Node } from 'react';

import {
  checkoutDetailsCls,
  checkoutDetailsRowCls,
  checkoutDetailsCardCls,
} from 'shared_components/checkout/sections/info/Details.less';

type Props = { children: Node };

export const CheckoutDetailsCard = ({ children }: Props) => (
  <div className={checkoutDetailsCardCls}>{children}</div>
);

/**
 * CheckoutDetailsRow
 *
 * @param {Props} props
 */
export const CheckoutDetailsRow = ({ children }: Props) => (
  <div className={checkoutDetailsRowCls}>{children}</div>
);

/**
 * CheckoutDetails
 *
 * @param {Props} props
 */
export const CheckoutDetails = ({ children }: Props) => (
  <div className={checkoutDetailsCls}>{children}</div>
);
