// @flow

import React, { Fragment, useState } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, Button } from '@riseart/common';
import { Overlay, OverlayHeader, OverlayBody, OverlayContainer } from '@riseart/layout';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';

import {
  confirmationCls,
  confirmationFooterCls,
} from 'shared_components/common/modal/Confirmation.less';

type Props = {
  title: Node | string,
  body: Function,
  children: Node,
  onConfirm?: Function,
  onReject?: Function,
  hideFooter?: boolean,
  closeComponent?: Node,
  loading?: boolean,
};

/**
 * ConfirmationModal
 *
 * @returns
 */
export function ConfirmationModal({
  children,
  title,
  body,
  onConfirm,
  onReject,
  hideFooter = false,
  closeComponent,
  loading = false,
}: Props): Node {
  const [isOpened, setIsOpened] = useState(false);

  const handleClose = () => {
    setIsOpened(!isOpened);
  };

  const handleOpen = () => {
    setIsOpened(!isOpened);
  };

  const handleReject = () => {
    if (typeof onReject === 'function') {
      onReject();
    }
    handleClose();
  };

  const actionElement = React.cloneElement(React.Children.only(children), {
    onClick: handleOpen,
  });

  return (
    <React.Fragment>
      {actionElement}
      {isOpened ? (
        <Overlay
          className={confirmationCls}
          isVisible
          type="semitransparent"
          size="medium"
          onClose={handleClose}
        >
          <OverlayContainer>
            <IsomorphicRipple isActive={loading}>
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <OverlayHeader border={false} closeComponent={closeComponent || <Fragment />}>
                <Heading tag="h3" level="3">
                  {title}
                </Heading>
              </OverlayHeader>
              <OverlayBody>{body({ handleClose, handleOpen })}</OverlayBody>
              {hideFooter ? null : (
                <footer className={confirmationFooterCls}>
                  <Button type="secondary" size="medium" onClick={handleReject}>
                    <FormattedMessage id="common.no">{(label: string) => label}</FormattedMessage>
                  </Button>
                  <Button
                    type="primary"
                    size="medium"
                    onClick={() => typeof onConfirm === 'function' && onConfirm(handleClose)}
                  >
                    <FormattedMessage id="common.yes">{(label: string) => label}</FormattedMessage>
                  </Button>
                </footer>
              )}
            </IsomorphicRipple>
          </OverlayContainer>
        </Overlay>
      ) : null}
    </React.Fragment>
  );
}
