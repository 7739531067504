// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { GiftBoxIcon } from '@riseart/icons';
import { MediaQuery } from '@riseart/common';
import { application as CONFIG_APP } from 'Config';

import { raScreenLg } from '@riseart/antd-provider/dist/website/variables.less';
import {
  xmaxCls,
  xmaxIconCls,
  xmaxContentCls,
  xmaxContentMainCls,
} from 'shared_components/messages/christmas/styles.less';

type Props = {
  storeCode: ?string,
};

/**
 * ChristmasDelivery
 *
 * @param {Props} props
 * @returns {Node}
 */
export const ChristmasDelivery = ({ storeCode }: Props): Node => {
  const intl = useIntl();
  const currencySign =
    CONFIG_APP.i18n.currency.signs[storeCode && storeCode.toLowerCase()] ||
    CONFIG_APP.i18n.currency.signs.uk;

  const formattedHTMLTags = { strong: (chunks) => <strong>{chunks}</strong> };

  return (
    <div className={xmaxCls}>
      <div className={xmaxIconCls}>
        <GiftBoxIcon />
      </div>
      <div className={xmaxContentCls}>
        <MediaQuery minWidth={raScreenLg}>
          {(isLarge) => (
            <Fragment>
              <span
                className={xmaxContentMainCls}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    {
                      id: 'messages.application.christmasDelivery.line1',
                    },
                    { currencySign, ...formattedHTMLTags },
                  ),
                }}
              />
              {isLarge ? ' - ' : ''}
              <span
                className={xmaxContentCls}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: `messages.application.christmasDelivery.${
                      isLarge ? 'line2' : 'line2Truncated'
                    }`,
                  }),
                }}
              />
            </Fragment>
          )}
        </MediaQuery>
      </div>
    </div>
  );
};
