// @flow

import { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';
import { BrowserStorage } from 'shared_services/riseart/storage/Browser';
import { STORAGE_TYPES } from 'shared_services/riseart/storage/Interfaces';

import type { Storage, StorageTypes } from 'shared_services/riseart/storage/Interfaces';

type Props = {
  isomorphic?: boolean,
  adapter?: Object,
  storageType?: StorageTypes,
  storageKey: string,
  dataMapper?: Function,
  children: Function,
};

/**
 * StorageConsumer
 *
 * @param {Props} props
 * @returns {Node}
 */
export function StorageConsumer({
  isomorphic = false,
  adapter: customAdapter,
  storageType,
  storageKey,
  dataMapper,
  children,
}: Props): Node {
  const [isMounted, setIsMounted] = useState(isomorphic);
  const adapter = useRef<Storage | null>(
    customAdapter ||
      (BrowserStorage && new BrowserStorage(storageType || STORAGE_TYPES.localStorage)) ||
      null,
  );
  const storedValue = adapter.current ? adapter.current.load(storageKey) : null;

  // Control when component was initally mounted. Only after that load the actual content on client,
  // to avoid any hydration errors of inconsistent component structure
  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted
    ? children(typeof dataMapper === 'function' ? dataMapper(storedValue) : storedValue)
    : null;
}
