// @flow

import React from 'react';
import { useIntl } from 'react-intl';
import { FormBuilder } from '@riseart/form';
import { CartDiscountFormModel } from 'shared_models/forms/CartDiscount';

type Props = { onSubmit: Function };

/**
 * CartDiscountForm
 *
 * @param {Props} props
 */
export const CartDiscountForm = ({ onSubmit }: Props) => {
  const intl = useIntl();

  return (
    <FormBuilder
      settings={CartDiscountFormModel.settings}
      fields={CartDiscountFormModel.fields}
      onSubmit={onSubmit}
      submitActionLabel={intl.formatMessage({ id: 'common.apply' })}
      customData={{}}
      intl={intl}
    />
  );
};
