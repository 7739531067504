export const CartGiftCardFormModel = {
  settings: {
    hideRequiredMark: true,
    layout: 'inline',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'voucherCode',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.common.validation_messages.notEmptyDiscount',
        },
      ],
      label: 'forms.cart.voucherCode',
      extra: 'forms.cart.voucherCodeHint',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'securityCode',
      validateTrigger: 'onBlur',
      label: 'forms.cart.securityCode',
      extra: 'forms.cart.securityCodeHint',
    },
  ],
};
