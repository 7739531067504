// extracted by mini-css-extract-plugin
var _1 = "ra-black-friday";
var _2 = "ra-black-friday-condensed";
var _3 = "ra-black-friday-content-cell";
var _4 = "ra-black-friday-content-cell-items";
var _5 = "ra-black-friday-content";
var _6 = "ra-black-friday-countdown-close";
var _7 = "ra-black-friday-countdown";
var _8 = "ra-black-friday-countdown-label";
var _9 = "ra-black-friday-light";
var _a = "ra-black-friday-subtitle-label";
var _b = "ra-black-friday-title";
var _c = "ra-black-friday-title-label";
var _d = "ra-black-friday-with-counter";
export { _1 as "blackFridayCls", _2 as "blackFridayCondensedCls", _3 as "blackFridayContentCellCls", _4 as "blackFridayContentCellItemsCls", _5 as "blackFridayContentCls", _6 as "blackFridayCountdownCloseCls", _7 as "blackFridayCountdownCls", _8 as "blackFridayCountdownLabelCls", _9 as "blackFridayLightCls", _a as "blackFridaySubTitleLabelCls", _b as "blackFridayTitleCls", _c as "blackFridayTitleLabelCls", _d as "blackFridayWithCounterCls" }
