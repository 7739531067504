// @flow

import React from 'react';
import type { Node } from 'react';

import { countryCls } from 'shared_components/common/Country.less';

type Props = {
  code: string,
  hideFlag?: boolean,
};

/**
 * Country
 *
 * @param {Props} props
 * @returns {Node}
 */
export function Country({ code, hideFlag = false }: Props): Node {
  if (typeof code !== 'string') {
    return code;
  }

  const codeUpperCase = code.toUpperCase();
  const isExcludedCountry = ['A1', 'A2', 'AP', 'O1', 'EU'].indexOf(codeUpperCase) > -1;
  const codeLowerCase = (!hideFlag || !isExcludedCountry) && code.toLowerCase();

  return (
    <span className={countryCls}>
      {hideFlag || isExcludedCountry ? (
        code
      ) : (
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${codeLowerCase}.png`}
          srcSet={`https://flagcdn.com/w40/${codeLowerCase}.png 2x`}
          alt={code}
        />
      )}
    </span>
  );
}
