// @flow

import get from 'lodash/get';
import { store as CONFIG_STORE } from 'Config';
import { getSystemUnits } from 'shared_services/riseart/utils/Utils';

/**
 * selectUnitSystem
 *
 * @param {Object} state
 * @returns {?string}
 */
export function selectUnitSystem(state: Object): Object {
  const unitSystem =
    get(state[CONFIG_STORE.keys.auth], 'data.payload.units') ||
    get(state[CONFIG_STORE.keys.me], 'data.visitor.unitSystem');
  const units = getSystemUnits(unitSystem);

  return {
    unitSystem,
    unitLength: get(units, 'length.unit'),
    unitWeight: get(units, 'weight.unit'),
  };
}
