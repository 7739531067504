// @flow

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Modal } from '@riseart/antd-provider';
import { CloseIcon } from '@riseart/icons';
import { mailingList as ENUM_MAILING_LIST } from 'Enum';
import { SubscribeFormModel } from 'shared_models/forms/Subscribe';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { FormBuilder } from '@riseart/form';

import {
  subscribeFormCls,
  subscribeFormThemeBasicCls,
  subscribeFormThemeNarrowCls,
  subscribeFormWrapperCls,
  subscribeFormBottomCls,
} from 'shared_components/forms/subscribe/Form.less';

type Props = {
  className?: string,
  children?: Function,
  form: Object,
  intl: Object,
  inputId: string,
  inputName: string,
  label: any,
  placeholderKey: string,
  buttonKey: string,
  labelKey: string,
  isLabelForm: boolean,
  onSubscribe: Function,
  theme?: string,
  quizResponseId: ?string,
  onLoadingChange: Function,
  handleChange?: ?Function,
  subscribeMutation: Function,
  showSuccessDialog?: boolean,
  onCloseDialog?: Function,
};

type State = {
  dialog: Object,
};

/**
 * Subscribe
 *
 */
class Subscribe extends Component<Props, State> {
  static defaultProps: Object = { showSuccessDialog: true, theme: 'basic' };

  /**
   * Constructor
   * @param {object} props
   */
  constructor(props: Props) {
    super(props);

    this.state = { dialog: { isActive: false, content: null } };
    this.bindMethods();
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.mutationHandler = this.mutationHandler.bind(this);
    this.dialogCancelHandler = this.dialogCancelHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
  }

  /**
   * handleLoading
   * @param {boolean} isLoading
   */
  handleLoading: Function;

  handleLoading(isLoading: boolean): void {
    const { onLoadingChange } = this.props;

    if (typeof onLoadingChange === 'function') {
      onLoadingChange(isLoading);
    }
  }

  /**
   * mutationHandler
   * @param {Object} response
   */
  mutationHandler: Function;

  mutationHandler(response: Object) {
    if (!response || !response.data || (response.data && !response.data.createMailingList)) {
      return;
    }

    const { createMailingList: responseData } = response.data;
    const { intl, showSuccessDialog } = this.props;
    const dialog = { isActive: true, title: null, content: null };
    this.handleLoading(false);

    if (responseData && responseData.id) {
      this.props.onSubscribe && this.props.onSubscribe(responseData);

      if (showSuccessDialog) {
        dialog.content = intl.formatMessage({ id: 'forms.subscribeForm.messages.success' });
      }
    }

    showSuccessDialog && this.setState({ dialog });
  }

  /**
   * DialogCancelHandler
   */
  dialogCancelHandler: Function;

  dialogCancelHandler() {
    if (typeof this.props.onCloseDialog === 'function') {
      this.props.onCloseDialog();
    }

    this.setState({ dialog: { isActive: false, content: null } });
  }

  /**
   * handleSubmit
   * @param { Props } props with non required quizResponseId
   * @returns {Function} Function to access error and data
   */
  handleSubmit: Function;

  handleSubmit({ quizResponseId }: Props): Function {
    return (data: Object): void => {
      this.handleLoading(true);
      this.props
        .subscribeMutation({
          variables: {
            inputMailingList: {
              type: ENUM_MAILING_LIST.type.TYPE_NEWSLETTER,
              emailAddress: data.emailSubscribe,
              ...(quizResponseId ? { quizResponseId } : null),
            },
          },
        })
        .then(this.mutationHandler);
    };
  }

  /**
   * Render component
   */
  render() {
    const { className, children, quizResponseId, intl, theme, buttonKey } = this.props;

    // Add an onError handler to the mutation otherwise it logs a promiseReactionJob error
    return (
      <div
        className={classNames(
          subscribeFormWrapperCls,
          theme === 'basic' && subscribeFormThemeBasicCls,
          theme === 'narrow' && subscribeFormThemeNarrowCls,
          className,
        )}
      >
        {this.props.showSuccessDialog ? (
          <Modal
            title={this.props.intl.formatMessage({
              id: 'forms.subscribeForm.messages.successTitle',
            })}
            open={this.state.dialog.isActive}
            closeIcon={<CloseIcon />}
            onCancel={this.dialogCancelHandler}
            footer={null}
          >
            {this.state.dialog.content || <IsomorphicRipple isActive />}
          </Modal>
        ) : null}
        {children && typeof children === 'function' ? (
          children({
            onSubmit: this.handleSubmit({ quizResponseId }),
            mutationHandler: this.mutationHandler,
          })
        ) : (
          <FormBuilder
            intl={intl}
            className={subscribeFormCls}
            settings={SubscribeFormModel.settings}
            fields={SubscribeFormModel.fields}
            submitActionLabel={intl.formatMessage({
              id: buttonKey || 'forms.subscribeForm.submitButtonText',
            })}
            onSubmit={this.handleSubmit({ quizResponseId })}
            buttonProps={{ type: theme === 'narrow' ? 'primary' : 'secondary' }}
          />
        )}

        <div className={subscribeFormBottomCls}>
          {intl.formatMessage({ id: 'forms.subscribeForm.subscribeTerms' })}
        </div>
      </div>
    );
  }
}

export const SubscribeForm = injectIntl(Subscribe);
