// @flow

import React from 'react';
import type { Node } from 'react';
import { Link } from '@riseart/common';
import { useLocale } from 'shared_services/redux/hooks/useLocale';

type Props = {
  to?: string,
  assembler: {
    handler: Function,
    args?: Array<any>,
    options?: Object,
  },
  children: Node,
};

/**
 * LocalizedLink
 *
 * @param {Props} props
 * @returns {Node}
 */
export const LocalizedLink = ({ to, assembler, children, ...restProps }: Props): Node => {
  const locale = useLocale();

  return typeof children !== 'function' ? (
    <Link
      to={
        to ||
        (assembler &&
          assembler.handler(...(assembler.args || []), { ...(assembler.options || null), locale }))
      }
      {...restProps}
    >
      {children}
    </Link>
  ) : (
    children(locale)
  );
};
