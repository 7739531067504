// @flow

import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ArrowUpLightIcon, ArrowLightIcon } from '@riseart/icons';
import { MediaQuery, Button } from '@riseart/common';
import { cookies as CONFIG_COOKIES, application as CONFIG_APP } from 'Config';
import { LocalizedLink } from 'shared_components/i18n/LocalizedLink';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { convertUTCToLocal } from 'shared_services/riseart/utils/Utils';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import { Countdown } from 'shared_components/common/countdown/Countdown';

import { raScreenSmMax } from '@riseart/antd-provider/dist/website/variables.less';
import {
  blackFridayCls,
  blackFridayCondensedCls,
  blackFridayTitleCls,
  blackFridayTitleLabelCls,
  blackFridayContentCls,
  blackFridayContentCellCls,
  blackFridayContentCellItemsCls,
  blackFridayCountdownCls,
  blackFridayCountdownLabelCls,
  blackFridayCountdownCloseCls,
} from 'shared_components/messages/BlackFriday.less';

type Props = {
  title?: string,
  titleMobile?: string,
  date: string,
  localTime: boolean,
  storeCode: ?string,
  showCountdownLabel?: boolean,
  onHeightChange: Function,
};

/**
 * BlackFridayMessage
 *
 * @param {Props} props
 * @returns {Node}
 */
export const BlackFridayMessage = ({
  title,
  titleMobile,
  date,
  localTime = false,
  showCountdownLabel = true,
  storeCode,
  onHeightChange,
}: Props): Node => {
  const [hideCodes, setHideCodes] = useState(
    Cookies.get(CONFIG_COOKIES.blackFridayCountdownSession.name) === 'true',
  );
  const currencySign =
    CONFIG_APP.i18n.currency.signs[storeCode && storeCode.toLowerCase()] ||
    CONFIG_APP.i18n.currency.signs.uk;
  const formattedHTMLTags = {
    strong: (chunk: string) => <strong>{chunk}</strong>,
    div: (chunk: string) => <div>{chunk}</div>,
    br: () => <br />,
  };
  const handleClick = () => {
    Cookies.set(
      CONFIG_COOKIES.blackFridayCountdownSession.name,
      !hideCodes,
      CONFIG_COOKIES.blackFridayCountdownSession,
    );
    setHideCodes(!hideCodes);
  };

  // Update height when message state changes (codes are shown/hidden)
  useEffect(() => {
    if (typeof onHeightChange === 'function') {
      onHeightChange();
    }
  }, [onHeightChange, hideCodes]);

  if (hideCodes) {
    return (
      <div className={classNames(blackFridayCls, blackFridayCondensedCls)}>
        <Button type="link" linkType="strong" width="container" onClick={handleClick}>
          <FormattedMessage id="components.appMessages.blackFriday.titleCondensed">
            {(label: string) => label}
          </FormattedMessage>
          <ArrowLightIcon className={blackFridayCountdownCloseCls} />
        </Button>
      </div>
    );
  }

  return (
    <div className={blackFridayCls}>
      <div className={blackFridayTitleCls}>
        <div className={blackFridayTitleLabelCls}>
          <LocalizedLink assembler={{ handler: UrlAssembler.artList }} title="" type="light">
            <MediaQuery maxWidth={raScreenSmMax}>
              {(isMobile) => (
                <FormattedMessage
                  id={
                    (isMobile && titleMobile) ||
                    title ||
                    `components.appMessages.blackFriday.${isMobile ? 'titleMobile' : 'title'}`
                  }
                  values={{
                    currencySign,
                    ...formattedHTMLTags,
                  }}
                >
                  {(label: string) => label}
                </FormattedMessage>
              )}
            </MediaQuery>
          </LocalizedLink>
        </div>
        {date ? (
          <MediaQuery maxWidth={raScreenSmMax}>
            {(isTablet) => (
              <div className={classNames(blackFridayContentCellCls, blackFridayCountdownCls)}>
                {isTablet && showCountdownLabel ? (
                  <span className={blackFridayCountdownLabelCls}>
                    <FormattedMessage id="components.appMessages.blackFriday.countdownLabelTruncated">
                      {(label: string) => `${label}`}
                    </FormattedMessage>
                  </span>
                ) : null}

                <Countdown
                  variant="outlined"
                  countdownDate={localTime ? convertUTCToLocal(date) : date}
                  labels={{
                    days: (
                      <FormattedMessage id="components.countdown.days">
                        {(label: string) => label}
                      </FormattedMessage>
                    ),
                    hours: (
                      <FormattedMessage id="components.countdown.hours">
                        {(label: string) => label}
                      </FormattedMessage>
                    ),
                    minutes: (
                      <FormattedMessage id="components.countdown.minutes">
                        {(label: string) => label}
                      </FormattedMessage>
                    ),
                    seconds: (
                      <FormattedMessage id="components.countdown.seconds">
                        {(label: string) => label}
                      </FormattedMessage>
                    ),
                  }}
                />
              </div>
            )}
          </MediaQuery>
        ) : null}
      </div>
      <div className={blackFridayContentCls}>
        <div className={blackFridayContentCellCls}>
          <div className={blackFridayContentCellItemsCls}>
            <FormattedMessage
              id="components.appMessages.blackFriday.art15"
              tagName="div"
              values={{
                currencySign,
                ...formattedHTMLTags,
              }}
            />
            <FormattedMessage
              id="components.appMessages.blackFriday.art250"
              tagName="div"
              values={{
                currencySign,
                ...formattedHTMLTags,
              }}
            />
            <FormattedMessage
              id="components.appMessages.blackFriday.art500"
              tagName="div"
              values={{
                currencySign,
                ...formattedHTMLTags,
              }}
            />
            <FormattedMessage
              id="components.appMessages.blackFriday.art1000"
              tagName="div"
              values={{
                currencySign,
                ...formattedHTMLTags,
              }}
            />
            <FormattedMessage
              id="components.appMessages.blackFriday.art8000"
              tagName="div"
              values={{
                currencySign,
                ...formattedHTMLTags,
              }}
            />
          </div>
        </div>
      </div>
      <ArrowUpLightIcon className={blackFridayCountdownCloseCls} onClick={handleClick} />
    </div>
  );
};
