// @flow

import React, { useCallback, useEffect } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Heading, MediaQuery } from '@riseart/common';
import { Overlay, OverlayHeader, OverlayBody, OverlayContainer } from '@riseart/layout';
import { WithOffsetHeight } from 'shared_hocs/gui/withOffsetHeight';
import { RegionalForm } from 'shared_components/forms/regional/Form';
import HOCFormRegional from 'shared_hocs/forms/Regional';
import { RouteConfigContext } from 'shared_data/providers/routeconfig/RouteConfigContext';
import { selectGui } from 'shared_services/redux/selectors/gui';
import { guiToggleRegionalSettings } from 'shared_services/redux/actions/application/gui';
import { calculateOverlayOffset } from 'shared_services/riseart/utils/Utils';

import { regionalOverlayCls } from 'shared_components/forms/regional/Overlay.less';
import { raScreenSm } from '@riseart/antd-provider/dist/website/variables.less';

const HOCRegionalForm = HOCFormRegional(RegionalForm);

type Props = {
  onClose?: Function,
};

/**
 * RegionalFormOverlay
 *
 * @returns
 */
export function RegionalFormOverlay({ onClose }: Props): Node {
  const dispatch = useDispatch();
  const guiState = useSelector(selectGui);

  function onSubmit() {
    if (typeof onClose === 'function') {
      onClose();
    }

    dispatch(guiToggleRegionalSettings({ open: false }));
  }

  const handleClose = useCallback(() => {
    dispatch(guiToggleRegionalSettings({ open: false }));

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [dispatch, onClose]);

  useEffect(() => () => dispatch(guiToggleRegionalSettings({ open: false })), [dispatch]);

  return (
    <MediaQuery minWidth={raScreenSm}>
      {(isScreenTablet) => (
        <WithOffsetHeight>
          {({ notificationsHeight, applicationsHeight }) => {
            const offset = isScreenTablet
              ? calculateOverlayOffset(notificationsHeight, applicationsHeight)
              : notificationsHeight;

            return (
              <Overlay
                sidebar
                sidebarAlign="right"
                closeOnOutsideClick
                isVisible={
                  guiState &&
                  guiState.regionalSettingsSidebar &&
                  guiState.regionalSettingsSidebar.open
                }
                topOffset={offset}
                type="semitransparent"
                className={regionalOverlayCls}
                onClose={handleClose}
              >
                <OverlayContainer>
                  <OverlayHeader border={false}>
                    <Heading tag="h3" level="3">
                      <FormattedMessage id="layouts.header.regionalOverlayTitle">
                        {(label: string) => label}
                      </FormattedMessage>
                    </Heading>
                  </OverlayHeader>
                  <OverlayBody>
                    <RouteConfigContext.Consumer>
                      {({ key, currentParams }) => (
                        <HOCRegionalForm
                          key="regional-form"
                          pageRouteKey={key}
                          currentRouteParams={currentParams}
                          onSubmit={onSubmit}
                        />
                      )}
                    </RouteConfigContext.Consumer>
                  </OverlayBody>
                </OverlayContainer>
              </Overlay>
            );
          }}
        </WithOffsetHeight>
      )}
    </MediaQuery>
  );
}
