// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '@riseart/common';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import {
  kazoartBannerCls,
  kazoartBannerImageCls,
  kazoartBannerContentCls,
} from 'shared_components/messages/Kazoart.less';

import kazoartIcon from 'shared_components/messages/assets/kazoart.png';

/**
 * Kazoart
 *
 * @param {Props} props
 * @returns {Node}
 */
export const Kazoart = (): Node => {
  return (
    <div className={kazoartBannerCls}>
      <div className={kazoartBannerImageCls}>
        <img src={kazoartIcon} alt="KAZoART" />
      </div>
      <div className={kazoartBannerContentCls}>
        <FormattedMessage
          id="messages.application.kazoart"
          values={{
            a: (chunks) => (
              <FormattedMessage id="components.art.gridNoArtworksLinkTitle">
                {(linkTitle: string) => (
                  <Link href={LocalizedConfig.get('externalUrls.kazoart')} title={linkTitle}>
                    {chunks}
                  </Link>
                )}
              </FormattedMessage>
            ),
          }}
        >
          {(text: string) => text}
        </FormattedMessage>
      </div>
    </div>
  );
};
