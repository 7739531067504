// @flow

import React from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { Link } from '@riseart/common';
import { isAbsoluteUrl } from '@riseart/fe-utils';
import { meta as META_ENUM } from 'Enum';
import { MetaProvider } from 'shared_data/providers/meta/Meta';
import { LDTYPES, generateLDJSON } from 'shared_models/seo/ldJSON';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { isRoutedUrl } from 'shared_services/riseart/utils/Utils';

import {
  breadcrumbsCls,
  breadcrumbsItemCls,
  breadcrumbsSeparatorCls,
} from 'shared_components/common/breadcrumbs/Breadcrumbs.less';

const { SCRIPT_LD_JSON_BREADCRUMBS } = META_ENUM.METATYPE;

type Props = {
  links: Array<Object>,
  separator?: string,
  generateLDJson?: boolean,
};

/**
 * Breadcrumbs
 *
 * @param {Props} props
 */
export const Breadcrumbs = ({
  separator = '|',
  links = [],
  generateLDJson = true,
}: Props): Node => {
  const { formatMessage } = useIntl();
  const combinedLinks = [
    {
      name: formatMessage({ id: 'components.breadcrumbs.home' }),
      url: UrlAssembler.byRouteKey('home'),
      isLink: true,
    },
    ...links,
  ];
  const lastIndex = combinedLinks.length - 1;

  return (
    <nav className={breadcrumbsCls}>
      {combinedLinks.map(({ name, url, isLink = true }, index) => (
        <React.Fragment key={url || name}>
          {!!index && separator ? (
            <span className={breadcrumbsSeparatorCls}>{separator}</span>
          ) : null}
          {isLink && index !== lastIndex ? (
            <Link
              type="light"
              className={breadcrumbsItemCls}
              {...(isRoutedUrl(url) ? { to: url } : { external: true, href: url })}
              title={name}
            >
              {name}
            </Link>
          ) : (
            <span className={breadcrumbsItemCls}>{name}</span>
          )}
        </React.Fragment>
      ))}

      {generateLDJson && (
        <MetaProvider
          meta={{
            [SCRIPT_LD_JSON_BREADCRUMBS]: generateLDJSON(
              LDTYPES.BREADCRUMBS,
              combinedLinks.map(({ url, ...rest }) => ({
                ...rest,
                url: isAbsoluteUrl(url) ? url : `${LocationManager.get('origin')}${url}`,
              })),
            ),
          }}
        />
      )}
    </nav>
  );
};
