// @flow
import { RiseartLogger } from 'shared_services/riseart/Logger';

import { STORAGE_TYPES } from 'shared_services/riseart/storage/Interfaces';
import type { Storage, StorageTypes } from 'shared_services/riseart/storage/Interfaces';

/**
 * storageAvailable
 * This function is taken from the MDN recommendate way of checking for available web storage
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 * @param {StorageTypes} type
 */
function storageAvailable(type: StorageTypes) {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof window.DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

/**
 * BrowserStorage
 */
export class BrowserStorage implements Storage {
  isSupported: boolean = true;

  storage: Object = null;

  /**
   * constructor
   * @param {StorageTypes} storageType
   */
  constructor(storageType?: StorageTypes = STORAGE_TYPES.localStorage) {
    this.storage = window[storageType];
    this.setup(storageType);
  }

  /**
   * Setup the adapter
   * @package {string} storageType
   */
  setup(storageType: StorageTypes): void {
    if (!storageAvailable(storageType)) {
      this.isSupported = false;

      throw new Error('The BrowserStorage adapter is not supported by the client');
    }
  }

  /**
   * Load an item from the storage adapter
   * @param {string} key
   * @returns {any} parsed json from storage
   */
  load(key: string): any {
    if (!this.isSupported) {
      return;
    }
    const storedObject = this.storage.getItem(key);

    if (storedObject === null || typeof storedObject === 'undefined') {
      return;
    }

    return JSON.parse(storedObject);
  }

  /**
   * Save an item inside the storage adapter
   * @param {string} key
   * @param {any} value
   * @returns {boolean} true for successfully saved value, false otherwise
   */
  save(key: string, value: any): boolean {
    if (!this.isSupported || value === null || typeof value === 'undefined') {
      return false;
    }

    try {
      this.storage.setItem(key, JSON.stringify(value));
      return true;
    } catch (Exception) {
      RiseartLogger.exception(Exception);
      return false;
    }
  }
}
