// extracted by mini-css-extract-plugin
var _1 = "#f4f2f1";
var _2 = "#d23c45";
var _3 = "#0500e8";
var _4 = "#343434";
var _5 = "#7c7876";
var _6 = "Roboto, Arial, Helvetica, clean, sans-serif";
var _7 = "14px";
var _8 = "1px solid #343434";
var _9 = "1px solid #0500e8";
var _a = "40px";
var _b = "4px 11px";
var _c = "20px";
var _d = "1px solid #f4f2f1";
var _e = "ra-payment-card-form";
var _f = "ra-payment-card-form-row";
var _10 = "ra-payment-card-form-input-brand";
var _11 = "ra-payment-card-form-input-cvc-container";
var _12 = "ra-payment-card-form-input";
var _13 = "ra-payment-card-form-input-cvc";
var _14 = "ra-payment-card-form-input-number";
var _15 = "ra-payment-card-form-input-stripe";
var _16 = "ra-payment-card-form-input-validthru";
var _17 = "ra-payment-card-form-input-item-stripe";
var _18 = "#343434";
var _19 = "ra-payment-step-card-row";
var _1a = "ra-payment-step-card-row-item";
var _1b = "ra-payment-step";
export { _1 as "colorBackground", _2 as "colorError", _3 as "colorSuccess", _4 as "colorText", _5 as "colorTextSecondary", _6 as "fontFamily", _7 as "fontSize", _8 as "inputBorder", _9 as "inputBorderFocus", _a as "inputHeight", _b as "inputPadding", _c as "inputVerticalMargin", _d as "paymentBoxBorder", _e as "paymentCardFormCls", _f as "paymentCardFormRowCls", _10 as "paymentCardInputBrandCls", _11 as "paymentCardInputCVCContainerCls", _12 as "paymentCardInputCls", _13 as "paymentCardInputCvcCls", _14 as "paymentCardInputNumberCls", _15 as "paymentCardInputStripeCls", _16 as "paymentCardInputValidThruCls", _17 as "paymentCardItemStripeCls", _18 as "paymentOptionTabSelectedBkg", _19 as "paymentStepCardRowCls", _1a as "paymentStepCardRowItemCls", _1b as "paymentStepCls" }
