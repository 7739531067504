// @flow

import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

type Props = {
  date?: string,
  countryCode: string,
  type?: 'long' | 'short',
};

/**
 * ShipsFrom
 *
 * @param {Props} props
 */
export const ShipsFrom = ({ date = null, countryCode, type = 'short' }: Props) => {
  if (date) {
    return (
      <FormattedDate
        value={new Date(date)}
        year="numeric"
        month="long"
        day="2-digit"
        weekday="long"
      >
        {(dateLabel: string) => (
          <FormattedMessage id={`countries.${countryCode}`}>
            {(countryName: string) => (
              <FormattedMessage
                id={`components.art.${type === 'short' ? 'shipsFromShort' : 'shipsFromLong'}`}
                values={{ country: countryName, date: dateLabel }}
              >
                {(shipsFromText: string) => shipsFromText}
              </FormattedMessage>
            )}
          </FormattedMessage>
        )}
      </FormattedDate>
    );
  }

  return (
    <FormattedMessage id={`countries.${countryCode}`}>
      {(countryName: string) => (
        <FormattedMessage id="components.art.shipsFromCountry" values={{ country: countryName }}>
          {(shipsFromText: string) => shipsFromText}
        </FormattedMessage>
      )}
    </FormattedMessage>
  );
};
