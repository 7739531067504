export const CartDiscountFormModel = {
  settings: {
    hideRequiredMark: true,
    layout: 'horizontal',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      name: 'discountCode',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.common.validation_messages.notEmptyDiscount',
        },
      ],
      placeholder: 'forms.cart.discountCodePlaceholder',
    },
  ],
};
