import cardValidator from 'card-validator';
import { cart as ENUM_CART } from 'Enum';
import { parseCardDate } from 'shared_services/riseart/utils/Cart';

export const CARD_TYPE_TRANSLATION_MAPPER = {
  [ENUM_CART.cardType.CARD_TYPE_MASTERCARD]: 'master',
  [ENUM_CART.cardType.CARD_TYPE_MASTERCARD_DEBIT]: 'master',
  [ENUM_CART.cardType.CARD_TYPE_MAESTRO]: 'maestro',
  [ENUM_CART.cardType.CARD_TYPE_VISA]: 'visa',
  [ENUM_CART.cardType.CARD_TYPE_VISA_DEBIT]: 'visa',
  [ENUM_CART.cardType.CARD_TYPE_VISA_ELECTRON]: 'visa',
  [ENUM_CART.cardType.CARD_TYPE_AMEX]: 'amex',
  [ENUM_CART.cardType.CARD_TYPE_DINERS]: 'diners',
  [ENUM_CART.cardType.CARD_TYPE_DISCOVER]: 'discover',
  [ENUM_CART.cardType.CARD_TYPE_JCB]: 'jcb',
  [ENUM_CART.cardType.CARD_TYPE_UNIONPAY]: 'unionpay',
  [ENUM_CART.cardType.CARD_TYPE_UNKNOWN]: 'unknown',
};

export const CartCardFormModel = {
  settings: {
    hideRequiredMark: true,
    layout: 'horizontal',
  },
  fields: [
    {
      tag: 'input',
      type: 'text',
      inputMode: 'numeric',
      name: 'cardNumber',
      validateTrigger: 'onBlur',
      validateFirst: true,
      rules: [
        {
          required: true,
          message: 'forms.checkout.validation.notEmptyCardNumber',
        },
        {
          message: 'forms.checkout.validation.notValidCardNumber',
          validator: (rule, value, cb) => {
            const cardNumberValidation = cardValidator.number(value);

            cb(cardNumberValidation && !cardNumberValidation.isValid ? true : undefined);
          },
        },
      ],
      label: 'forms.checkout.cardNumber',
    },
    {
      tag: 'input',
      type: 'text',
      name: 'cardOwner',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: 'forms.checkout.validation.notEmptyCardOwner',
        },
      ],
      label: 'forms.checkout.cardOwner',
    },
    {
      tag: 'input',
      type: 'text',
      inputMode: 'numeric',
      name: 'cardValidThru',
      validateTrigger: 'onBlur',
      validateFirst: true,
      rules: [
        {
          required: true,
          message: 'forms.checkout.validation.notEmptyCardValidThru',
        },
        {
          message: 'forms.checkout.validation.notValidCardValidThru',
          validator: (rule, value, cb) => {
            const expirationDateValidator = cardValidator.expirationDate(value);
            const { month, year } = parseCardDate(value);

            cb(
              (expirationDateValidator && !expirationDateValidator.isValid) || !month || !year
                ? true
                : undefined,
            );
          },
        },
      ],
      label: 'forms.checkout.expirityDate',
      placeholder: 'forms.checkout.expirityDatePlaceholder',
    },
    {
      tag: 'input',
      type: 'text',
      inputMode: 'numeric',
      pattern: '[0-9]*',
      name: 'cardSecurity',
      validateTrigger: 'onBlur',
      validateFirst: true,
      maxLength: 4,
      rules: [
        {
          required: true,
          message: 'forms.checkout.validation.notEmptyCardSecurity',
        },
        // Depending on the use case, the rules list might be updated
        // with additional rules added by components (e.g. the chackout payment card form)
        // which can inject as dependencies form values, additional form delated loginc, etc.
      ],
      label: 'forms.checkout.cvc',
      placeholder: 'forms.checkout.cvc',
    },
    {
      tag: 'input',
      type: 'text',
      inputMode: 'numeric',
      name: 'cardStartDate',
      validateFirst: true,
      rules: [
        {
          message: 'forms.checkout.validation.cardStartDateNotInPast',
          validator: (rule, value, cb) => {
            const { month, year } = parseCardDate(value);
            const currentMonth = new Date().getMonth() + 1;
            const currentYear = new Date().getFullYear();

            cb(
              value && (year > currentYear || (currentYear === year && currentMonth < month))
                ? true
                : undefined,
            );
          },
        },
      ],
      label: 'forms.checkout.startDate',
      placeholder: 'forms.checkout.expirityDatePlaceholder',
    },
    {
      tag: 'input',
      type: 'number',
      inputMode: 'numeric',
      name: 'cardIssueNumber',
      label: 'forms.checkout.issueNumber',
      rules: [
        {
          pattern: '^[0-9]*$',
          message: 'forms.common.validation_messages.not_valid_offer',
        },
      ],
    },
  ],
};
