// @flow

import classNames from 'classnames';
import React, { useCallback } from 'react';
import type { Node } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Link } from '@riseart/common';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import {
  gtmConsentReject,
  gtmConsentAccept,
} from 'shared_data/providers/google/tagmanager/Actions';

import {
  consentMsgCls,
  consentMsgTextCls,
  consentMsgButtonsCls,
  consentMsgButtonsSingleCls,
  consentMsgMainBtnCls,
} from 'shared_components/messages/Consent.less';

/**
 * ConsentMessage
 *
 * @param {Props} props
 * @returns {Node}
 */
export const ConsentMessage = ({ handleDismiss, content, shippingCountryCode }: Object): Node => {
  const dispatch = useDispatch();
  const handleReject = useCallback(() => {
    dispatch(gtmConsentReject());
    handleDismiss();
  }, [handleDismiss, dispatch]);

  const handleAccept = useCallback(() => {
    dispatch(gtmConsentAccept());
    handleDismiss();
  }, [handleDismiss, dispatch]);

  const { message, reject, accept } =
    (content &&
      content.find(
        ({ showForCountryCode }) =>
          showForCountryCode && showForCountryCode.indexOf(shippingCountryCode) > -1,
      )) ||
    {};

  return message ? (
    <div className={consentMsgCls}>
      <div className={consentMsgTextCls}>
        <FormattedMessage id={message}>{(text: string) => text}</FormattedMessage>{' '}
        <FormattedMessage id="common.learnMore">
          {(text: string) => (
            <Link
              to={LocalizedConfig.get('externalUrls.privacyPolicy')}
              title={text}
              target="_blank"
            >
              {text}
            </Link>
          )}
        </FormattedMessage>
      </div>
      {accept || reject ? (
        <div
          className={classNames(
            consentMsgButtonsCls,
            !accept || !reject ? consentMsgButtonsSingleCls : null,
          )}
        >
          {accept ? (
            <Button
              type="ghost"
              size="medium"
              className={consentMsgMainBtnCls}
              onClick={handleAccept}
            >
              <FormattedMessage id={accept}>{(text: string) => text}</FormattedMessage>
            </Button>
          ) : null}
          {reject ? (
            <Button type="ghost" size="medium" onClick={handleReject}>
              <FormattedMessage id={reject}>{(text: string) => text}</FormattedMessage>
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  ) : null;
};
