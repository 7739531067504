// @flow

import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LocationManager } from 'shared_services/riseart/url/Location';
import {
  gtmArtworkListView,
  gtmArtworkListItemSelected,
} from 'shared_data/providers/google/tagmanager/Actions';

/**
 * useListTracking
 *
 * @param {Array<Object>} items
 * @param {Object} tracking?
 * @param {Function} onClick?
 * @returns {Object}
 */
export const useListTracking = (
  items: Array<Object>,
  tracking?: Object,
  onClick?: Function,
): Object => {
  const [isInitialised, setIsInitialised] = useState(false);
  const [wasVisible, setWasVisible] = useState(null);
  const dispatch = useDispatch();
  const { enabled, enableInitial, enableVisibility, enableClick, name } = tracking || {};
  const location = LocationManager.get('fullPath');
  const trackListView = useCallback(
    (name, location, items) => dispatch(gtmArtworkListView(name, location, items)),
    [dispatch],
  );
  const trackListItemSelected = useCallback(
    (name, location, item) => dispatch(gtmArtworkListItemSelected(name, location, item)),
    [dispatch],
  );

  useEffect(() => {
    if (!isInitialised && items && items.length) {
      setIsInitialised(true);

      if (enabled && enableInitial) {
        trackListView(name, location, items);
      }
    }
    return () => {};
  }, [enabled, enableInitial, isInitialised, name, location, items, trackListView]);

  const handleItemClick = useCallback(
    (item: Object) => (e: Object, artCardProps: Object) => {
      if (enabled && enableClick) {
        trackListItemSelected(name, location, item);
      }

      if (typeof onClick === 'function') {
        onClick(e, artCardProps, item);
      }
    },
    [enabled, enableClick, name, location, onClick, trackListItemSelected],
  );

  const handleVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (isVisible && wasVisible === null) {
        if (enabled && enableVisibility && !enableInitial) {
          trackListView(name, location, items);
        }

        setWasVisible(true);
      }
    },
    [wasVisible, enabled, enableInitial, enableVisibility, name, location, items, trackListView],
  );

  return {
    trackListView,
    trackListItemSelected,
    handleItemClick,
    handleVisibilityChange,
  };
};
