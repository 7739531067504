// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { graphql } from '@apollo/client/react/hoc';
import { bindActionCreators } from 'redux';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectVisitorId } from 'shared_services/redux/selectors/visitor';
import { HOCFavorites } from 'shared_hocs/arts/Favorite.js';
import { addFavorite, removeFavorite } from 'shared_services/redux/actions/favorites/favorites';
import { FavoriteIcon } from '@riseart/wishlist';
import CREATE_MUTATION from 'shared_data/queries/favorites/create.graphql';
import DELETE_MUTATION from 'shared_data/queries/favorites/delete.graphql';

type Props = {
  type: ?string,
  animated?: boolean,
  size?: string,
  width?: string,
  showText?: boolean,
  children: Node,
  className: ?string,
  artId: number,
  favoriteId: number,
  isFavorited: boolean,
  position: string,
  storeCode: string,
  visitorId: string,
  onClick?: Function,
  onLoading?: Function,
  onAfterFavorite: Function,
  onAfterUnfavorite: Function,
  removeFavorite: Function,
  addFavorite: Function,
  actionRemoveFavorite: Function,
  actionAddFavorite: Function,
};

type State = {
  isLoading: boolean,
};

/**
 * FavoritesToggle
 *
 * @param {Props} props
 */
class FavoritesToggle extends Component<Props, State> {
  static defaultProps: Object = {
    onClick: null,
    onLoading: null,
    onAfterFavorite: null,
    onAfterUnfavorite: null,
    animated: true,
    size: null,
    type: null,
    width: null,
    showText: false,
  };

  /**
   * constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = { isLoading: false };

    this.bindMethod();
  }

  /**
   * handleClick
   */
  handleClick: Function;

  handleClick() {
    const { isFavorited, addFavorite, removeFavorite, visitorId, favoriteId, artId, onClick } =
      this.props;

    if (typeof onClick === 'function') {
      onClick(this.props);
    } else {
      this.handleLoading(true);

      const toggleAction = isFavorited
        ? removeFavorite(visitorId, favoriteId)
        : addFavorite(visitorId, artId);

      toggleAction
        .then(() => {
          this.handleAfterUpdate(!isFavorited);
          this.handleLoading(false);
        })
        .catch(() => {
          this.handleLoading(false);
        });
    }
  }

  /**
   * handleLoading
   *
   * @param {boolean} isLoading
   */
  handleLoading(isLoading: boolean) {
    const { onLoading } = this.props;

    this.setState({ isLoading });

    if (onLoading && typeof onLoading === 'function') {
      onLoading(isLoading);
    }
  }

  /**
   * handleAfterUpdate
   *
   * @param {boolean} wasFavorited
   */
  handleAfterUpdate(wasFavorited: boolean) {
    const { onAfterFavorite, onAfterUnfavorite } = this.props;

    if (wasFavorited) {
      if (onAfterFavorite && typeof onAfterFavorite === 'function') {
        onAfterFavorite();
      }
    } else if (onAfterUnfavorite && typeof onAfterUnfavorite === 'function') {
      onAfterUnfavorite();
    }
  }

  /**
   * bindMethod
   */
  bindMethod() {
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * render
   */
  render() {
    const { animated, children, isFavorited, position, type, size, width, showText, className } =
      this.props;
    const { isLoading } = this.state;

    return children && typeof children === 'function' ? (
      children({ ...this.props, onClick: this.handleClick, isLoading })
    ) : (
      <FormattedMessage id={isFavorited ? 'common.removeFromWishlist' : 'common.addToWishlist'}>
        {(text: string) => (
          <FavoriteIcon
            animated={animated}
            type={type}
            size={size}
            isFavorited={isFavorited}
            isLoading={isLoading}
            onClick={this.handleClick}
            position={position}
            className={className}
            buttonProps={{
              title: text,
              'aria-label': text,
              width,
            }}
          >
            {children || (showText && text)}
          </FavoriteIcon>
        )}
      </FormattedMessage>
    );
  }
}

/**
 * withCreateFavorite
 */
const withCreateFavorite = graphql(CREATE_MUTATION, {
  props: ({ ownProps, mutate }: Object) => ({
    addFavorite() {
      return mutate({
        variables: {
          visitorId: ownProps.visitorId,
          artId: ownProps.artId,
          store: ownProps.storeCode,
        },
        ...(ownProps.refetchQueries ? { refetchQueries: ownProps.refetchQueries } : {}),
      }).then((result) => ownProps.actionAddFavorite(result.data.createFavorite));
    },
  }),
});

/**
 * withDeleteFavorite
 */
const withDeleteFavorite = graphql(DELETE_MUTATION, {
  props: ({ ownProps, mutate }) => ({
    removeFavorite() {
      return mutate({
        variables: { id: ownProps.favoriteId, store: ownProps.storeCode },
        ...(ownProps.refetchQueries ? { refetchQueries: ownProps.refetchQueries } : {}),
      }).then((result) => ownProps.actionRemoveFavorite(result.data.deleteFavorite));
    },
  }),
});

/**
 * FavoritesToggleMutation
 */
export const FavoritesToggleMutation = connect(
  (state) => ({
    visitorId: selectVisitorId(state),
    storeCode: selectStoreCode(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        actionAddFavorite: addFavorite,
        actionRemoveFavorite: removeFavorite,
      },
      dispatch,
    ),
)(HOCFavorites(withDeleteFavorite(withCreateFavorite(FavoritesToggle))));
