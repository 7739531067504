// @flow

import React from 'react';
import type { Node } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { MenuVertical, MenuVerticalItemPrefix } from '@riseart/layout';
import { BagIcon, WishlistIcon, GiftCardIcon, ProfileIcon } from '@riseart/icons';
import { application as CONFIG_APP } from 'Config';
import { meta as META_ENUM, user as USER_ENUM } from 'Enum';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { I18nComponentsLoader } from 'shared_components/i18n/ComponentsLoader';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { selectUserProperty } from 'shared_services/redux/selectors/user';
import { selectAuthShippingCountryCode } from 'shared_services/redux/selectors/auth';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';
import { Avatar } from 'shared_components/common/avatar/Avatar';
import { RegionalLabel } from 'shared_components/common/regional/Label';
import { toggleRecentArtworksWidget } from 'shared_components/arts/widgets/RecentArtworks';

import { raVisibilityShowForSmallOnly } from '@riseart/antd-provider/dist/website/variables.less';

export type MenuProps = {
  multiple?: boolean,
  resetState?: boolean,
  handleOverlay?: Function,
  showRecentWidgetOnPage?: boolean,
};

/**
 * VerticalMenu
 *
 * @param {Props} props
 * @returns {Node}
 */
export function VerticalMenu({
  multiple = false,
  resetState = false,
  handleOverlay,
  showRecentWidgetOnPage = false,
}: MenuProps): Node {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const userRole = useSelector(selectUserProperty('role'));
  const avatarLabel =
    useSelector(selectUserProperty('firstName')) ||
    formatMessage({ id: 'layouts.header.topmenu.avatarLabel' });
  const userAvatars = useSelector(selectUserProperty('avatars'));
  const shippingCountryCode = useSelector(selectAuthShippingCountryCode);
  const storeCode = useStoreCode() || CONFIG_APP.store.default;

  return (
    <I18nComponentsLoader dataKey="settings">
      {({ mainNavigation }) => {
        if (!mainNavigation || !mainNavigation.length) {
          return null;
        }

        const navigation = [
          ...mainNavigation,
          {
            href: UrlAssembler.byRouteKey('myWishlist'),
            label: formatMessage({ id: 'layouts.header.topmenu.wishlist' }),
            prefix: <MenuVerticalItemPrefix element={<WishlistIcon />} />,
            rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            obfuscate: true,
            obflinkProps: { stopPropagation: true },
            columns: [
              {
                items: [
                  {
                    href: UrlAssembler.byRouteKey('myWishlist'),
                    label: formatMessage({ id: 'layouts.header.topmenu.myWishlist' }),
                    rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                    obfuscate: true,
                  },
                  {
                    href: UrlAssembler.byRouteKey('myArtists'),
                    label: formatMessage({ id: 'layouts.header.topmenu.myArtists' }),
                    rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                    obfuscate: true,
                  },
                  {
                    label: formatMessage({ id: 'layouts.header.topmenu.recentArt' }),
                    href: '',
                    onClick: (e: Event, props: Object) => {
                      e.preventDefault();

                      // eslint-disable-next-line
                      if (props && props.onLinkClick) {
                        // eslint-disable-next-line
                        props.onLinkClick();
                      }

                      toggleRecentArtworksWidget(true, dispatch, showRecentWidgetOnPage);
                    },
                  },
                ],
              },
            ],
          },
          {
            ...(userRole
              ? {
                  href: UrlAssembler.byRouteKey('myPrivacy'),
                  label: null,
                  prefix: (
                    <MenuVerticalItemPrefix
                      element={
                        <Avatar images={userAvatars} size="small" name={avatarLabel}>
                          {avatarLabel}
                        </Avatar>
                      }
                    />
                  ),
                  rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                }
              : {
                  href: UrlAssembler.byRouteKey('login'),
                  label: formatMessage({ id: 'layouts.header.topmenu.profile' }),
                  prefix: <MenuVerticalItemPrefix element={<ProfileIcon />} />,
                  rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                  obfuscate: true,
                  obflinkProps: { stopPropagation: true },
                }),
            columns: [
              {
                items: [
                  ...(userRole
                    ? [
                        {
                          label: formatMessage({ id: 'layouts.header.topmenu.profile' }),
                          href: UrlAssembler.byRouteKey('myProfile'),
                          rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                        },
                        {
                          label: formatMessage({ id: 'layouts.header.topmenu.account' }),
                          href: UrlAssembler.byRouteKey('myAccount'),
                          rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                        },
                        {
                          href: UrlAssembler.byRouteKey('myPrivacy'),
                          label: formatMessage({ id: 'layouts.header.topmenu.myPrivacy' }),
                          rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                        },
                        {
                          href: UrlAssembler.byRouteKey('myOffers'),
                          label: formatMessage({ id: 'layouts.header.topmenu.myOffers' }),
                          rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                        },
                        ...(userRole === USER_ENUM.role.ROLE_ARTIST
                          ? [
                              {
                                label: formatMessage({
                                  id: 'layouts.header.topmenu.sellerConsole',
                                }),
                                href: LocalizedConfig.get('externalUrls.sellerConsole'),
                                external: true,
                                target: '_blank',
                                rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                              },
                            ]
                          : []),
                        {
                          href: {
                            pathname: UrlAssembler.byRouteKey('signout'),
                            state: { forward: LocationManager.get('search') },
                          },
                          label: formatMessage({ id: 'layouts.header.topmenu.signOut' }),
                          rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                        },
                      ]
                    : [
                        {
                          href: UrlAssembler.byRouteKey('login'),
                          label: formatMessage({ id: 'layouts.header.topmenu.signIn' }),
                          rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                          obfuscate: true,
                        },
                        {
                          href: UrlAssembler.byRouteKey('register'),
                          label: formatMessage({ id: 'layouts.header.topmenu.signUp' }),
                          rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
                          obfuscate: true,
                        },
                      ]),
                ],
              },
            ],
          },
          {
            href: UrlAssembler.cart('cart'),
            label: formatMessage({ id: 'layouts.header.topmenu.basket' }),
            prefix: <MenuVerticalItemPrefix element={<BagIcon />} />,
            rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            obfuscate: true,
          },
          {
            className: raVisibilityShowForSmallOnly,
            component: ({ onLinkClick: closeMenu }: Object) => (
              <RegionalLabel
                countryCode={shippingCountryCode}
                storeCode={storeCode}
                onClick={closeMenu}
              />
            ),
            rel: META_ENUM.ATTRIBUTE_CONTENT.NO_FOLLOW,
            obfuscate: true,
            obflinkProps: { stopPropagation: true },
          },
          {
            href: LocalizedConfig.get('externalUrls.giftCard'),
            external: true,
            label: formatMessage({ id: 'layouts.header.topmenu.giftCard' }),
            prefix: <MenuVerticalItemPrefix element={<GiftCardIcon />} />,
          },
        ];

        return (
          <MenuVertical
            items={navigation}
            multiple={multiple}
            resetState={resetState}
            handleOverlay={handleOverlay}
          />
        );
      }}
    </I18nComponentsLoader>
  );
}
