// @flow

import { useContext } from 'react';
import { UrlTranslationsContext } from 'shared_data/providers/url/TanslationsContext';

/**
 * useUrlTranslatedParams
 *
 * @returns {Object}
 */
export function useUrlParams(): Object {
  return useContext(UrlTranslationsContext);
}
