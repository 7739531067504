// @flow

import trimEnd from 'lodash/trimEnd';
import queryString from 'query-string';
import { NavigatorService } from 'shared_services/riseart/Navigator';
import { convertUTCToLocal } from 'shared_services/riseart/utils/Utils';
import { RiseartLogger } from 'shared_services/riseart/Logger';

/**
 * compare
 *
 * @param {string} compare
 * @param {string} value
 * @param {string} operator
 * @returns {boolean}
 */
function compare(compare: string, value: string, operator: string): boolean {
  switch (operator) {
    case 'starts':
      return compare.indexOf(value) === 0;
    case 'regex':
      return RegExp(value, 'g').test(compare);
    case 'lt':
      return compare < value;
    case 'lte':
      return compare <= value;
    case 'gt':
      return compare > value;
    case 'gte':
      return compare >= value;
    case 'exact':
    case 'bool':
      return compare === value;
    case 'eq':
      return Array.isArray(value) ? value.indexOf(compare) > -1 : compare === value;
    case 'neq':
      return Array.isArray(value) ? value.indexOf(compare) === -1 : compare !== value;
    default:
      return false;
  }
}

/**
 * matchConditionHandlers
 *
 * Key mapped object with handlers based on message condition key
 */
const matchConditionHandlers = {
  path: (parameters, operator, value, { location: { pathname } }) => {
    return compare(trimEnd(pathname, '/'), value, operator);
  },
  url: (parameters, operator, value, { location: { pathname, search } }) => {
    return compare(`${trimEnd(pathname, '/')}${search}`, value, operator);
  },
  referer: (parameters, operator, value) => {
    return document.referrer ? compare(trimEnd(document.referrer, '/'), value, operator) : false;
  },
  useragent: (parameters, operator, value) => {
    const [property, ...restParams] = parameters;
    const userAgent = NavigatorService.getParsedUserAgent();
    let fieldData = {};
    let valueModifier = null;
    if (property === 'device') {
      fieldData = userAgent.getDevice();
    } else if (property === 'browser') {
      fieldData = userAgent.getBrowser();

      const [firstParam] = restParams;

      // if requested browser version, parseFloat the value before comparing
      if (firstParam === 'version') {
        valueModifier = parseFloat;
      }
    }
    let compareValue = restParams.reduce(
      (aggregator, item) => (aggregator ? aggregator[item] : null),
      fieldData,
    );

    if (valueModifier) {
      compareValue = valueModifier(compareValue);
    }

    // $FlowFixMe
    return compare(compareValue, value, operator);
  },
  query: (parameters, operator, value, { location }) => {
    const [compareQueryParam] = parameters;
    const queryStringParams = queryString.parse(location.search);

    // Parameters exists in url regardless of its value
    if (operator === 'exists') {
      return queryStringParams[compareQueryParam] !== undefined;
    }

    // Parameters does not exists in url regardless of its value
    if (operator === 'nexists') {
      return queryStringParams[compareQueryParam] === undefined;
    }

    // Parameter exists and it has an exact value
    return !!(
      location &&
      location.search !== null &&
      queryStringParams[compareQueryParam] &&
      queryStringParams[compareQueryParam] === value
    );
  },
  customData: (parameters, operator, value, data) => {
    const compareValue = parameters.reduce(
      (aggregator, item) => (aggregator ? aggregator[item] : null),
      data,
    );

    return compare(compareValue, value, operator);
  },
  date: (parameters, operator, value, { options }) => {
    const [compareDateValue] = parameters;
    const conditionDate = options.localTime
      ? convertUTCToLocal(value).getTime()
      : new Date(value).getTime();
    const compareDate = (
      compareDateValue === 'today' ? new Date() : new Date(compareDateValue)
    ).getTime();

    // eslint-disable-next-line
    if (isNaN(conditionDate) || isNaN(compareDate)) {
      RiseartLogger.exception(
        new Error('Provided show message conditions for dates are not valid.'),
        {
          scope: 'ApplicationMessages.matchDates',
          param: parameters,
          value,
        },
      );

      return false;
    }

    // $FlowFixMe
    return compare(compareDate, conditionDate, operator);
  },
};

/**
 * matchCondition
 *
 * @param {string} condition
 * @param {string} value
 * @param {string} separator
 * @param {?Object} options
 * @returns {boolean}
 */
export function matchCondition(
  condition: string,
  value: string,
  separator: string = ':',
  options: ?Object = null,
): boolean {
  const [conditionMatcher, ...conditionParams] = condition.split(separator);
  const mathOperator = conditionParams.pop();
  const handler = matchConditionHandlers[conditionMatcher];

  if (typeof handler === 'function') {
    return handler(conditionParams, mathOperator, value, options);
  }

  RiseartLogger.exception(new Error('Provided message condition is not valid.'), {
    scope: 'ApplicationMessages.matchCondition',
    condition,
    value,
    options,
  });

  return false;
}
