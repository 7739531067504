// @flow

import classNames from 'classnames';
import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Paragraph, MediaQuery } from '@riseart/common';
import { VisuallySimilarIcon } from '@riseart/icons';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';

import { raScreenSm } from '@riseart/antd-provider/dist/website/variables.less';
import {
  searchVisualHintCls,
  searchVisualHintSuggestionsCls,
} from 'shared_components/forms/search/visual/Hint.less';

type Props = {
  showSuggestions?: boolean,
  onLinkClick?: Function,
};

/**
 * VisualSearchHint
 *
 * @returns {Node}
 */
export function VisualSearchHint({ showSuggestions = false, onLinkClick }: Props): Node {
  return (
    <Paragraph
      className={classNames(searchVisualHintCls, showSuggestions && searchVisualHintSuggestionsCls)}
    >
      <MediaQuery minWidth={raScreenSm}>
        {(isTablet) => (
          <FormattedMessage
            id={isTablet ? 'forms.search.visual.searchHint' : 'forms.search.visual.searchHintShort'}
            values={{
              a: (chunks) => (
                <Link
                  obfuscate
                  rel="nofollow"
                  to={UrlAssembler.artList({ search: { v: '' } })}
                  title={chunks}
                  {...(onLinkClick ? { onClick: onLinkClick } : null)}
                >
                  <VisuallySimilarIcon />
                  {chunks}
                </Link>
              ),
            }}
          >
            {(hint: string) => hint}
          </FormattedMessage>
        )}
      </MediaQuery>
    </Paragraph>
  );
}
