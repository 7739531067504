// @flow

import React, { useEffect } from 'react';
import type { Node } from 'react';
import { addBodyClassName } from '@riseart/fe-utils';
import { SearchResults } from 'shared_components/search/Results';

import { disableScrollCls } from 'shared_styles/app.less';

/**
 * HeaderSearchResults
 *
 * @param {Props} props
 * @returns {Node}
 */
export function HeaderSearchResults(props: Object): Node {
  // Apply a classname to disable main page scrolling
  useEffect(() => {
    // Disable scrolling on mount
    addBodyClassName(disableScrollCls, true);

    // Enable scrolling on unmount
    return () => addBodyClassName(disableScrollCls, false);
  }, []);

  return <SearchResults {...props} />;
}
