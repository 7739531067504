// @flow

import React from 'react';
import type { Node } from 'react';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { FormBuilder } from '@riseart/form';
import { application as CONFIG_APP } from 'Config';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { RegionalFormModel } from 'shared_models/forms/Regional';
import UPDATE_ME_MUTATION from 'shared_data/queries/me/update.graphql';

type Props = {
  visitorId: string | number,
  locale: string,
  store: string,
  shippingCountry: string,
  unitSystem: string,
  loadingCreate: boolean,
  onComplete: Function,
};

/**
 * RegionalForm
 */
export const RegionalForm = ({
  visitorId,
  locale,
  store,
  shippingCountry,
  unitSystem,
  loadingCreate,
  onComplete,
}: Props): Node => {
  const intl = useIntl();
  const [updateMe, { loading }] = useMutation(UPDATE_ME_MUTATION, { onCompleted: onComplete });

  return (
    <div>
      <IsomorphicRipple
        isActive={loading}
        styleConfig={{
          medium: { backgroundColor: 'transparent' },
          large: { backgroundColor: 'transparent' },
        }}
      >
        <FormBuilder
          // Set visitorId as component key, so that it rebuilds it if the visitor has changed
          key={visitorId}
          intl={intl}
          settings={RegionalFormModel.settings}
          fields={RegionalFormModel.fields}
          initialValues={{
            locale,
            store,
            country: shippingCountry,
            unitSystem: unitSystem || CONFIG_APP.units.defaultSystem,
          }}
          submitActionLabel={intl.formatMessage({
            id: loadingCreate ? 'forms.common.wait' : 'forms.common.saveSettings',
          })}
          onSubmit={(data) => {
            updateMe({
              variables: {
                inputMeVisitor: {
                  unitSystem: data.unitSystem,
                  locale: data.locale,
                  storeCode: data.store,
                  shippingCountryCode: data.country,
                },
              },
            });
          }}
        />
      </IsomorphicRipple>
    </div>
  );
};
