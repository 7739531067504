// @flow

import classNames from 'classnames';
import React, { useCallback } from 'react';
import type { Node } from 'react';
import { useDispatch } from 'react-redux';
import { application as CONFIG_APP } from 'Config';
import { Button } from '@riseart/common';
import { Country } from 'shared_components/common/Country';
import { guiToggleRegionalSettings } from 'shared_services/redux/actions/application/gui';

import {
  regionalLabelCls,
  regionalLabelPlaceholderCls,
} from 'shared_components/common/regional/Label.less';

type Props = {
  storeCode: string,
  countryCode?: string | null,
  component?: string,
  className?: string | null,
  onClick?: Function,
  disableReduxAction?: boolean,
};

/**
 * RegionalLabel
 *
 * @param {Props} props
 * @returns {Node}
 */
export function RegionalLabel({
  storeCode,
  countryCode,
  component,
  className,
  onClick,
  disableReduxAction = false,
}: Props): Node {
  const Component = component || Button;
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (!disableReduxAction) {
      dispatch(guiToggleRegionalSettings({ open: true }));
    }

    if (typeof onClick === 'function') {
      onClick();
    }
  }, [dispatch, disableReduxAction, onClick]);

  return (
    <Component
      type="link"
      linkType="light"
      className={classNames(regionalLabelCls, className)}
      onClick={handleClick}
    >
      {countryCode ? (
        <Country code={countryCode} />
      ) : (
        <span className={regionalLabelPlaceholderCls} />
      )}
      {CONFIG_APP.i18n.currency.signs[storeCode]} ({CONFIG_APP.i18n.currency.codes[storeCode]})
    </Component>
  );
}
