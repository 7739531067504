// @flow

import React from 'react';
import classNames from 'classnames';
import { CloseIcon } from '@riseart/icons';
import { iconCloseBtn } from 'shared_components/common/button/IconClose.less';

type Props = {
  clickListener: Function,
  className?: string,
};

export const IconClose = (componentProps: Props) => (
  <CloseIcon
    className={classNames(iconCloseBtn, componentProps.className)}
    onClick={componentProps.clickListener}
  />
);
