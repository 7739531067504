// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { VanIcon } from '@riseart/icons';
import { MediaQuery } from '@riseart/common';
import { application as CONFIG_APP } from 'Config';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';

import { raScreenMd } from '@riseart/antd-provider/dist/website/variables.less';
import {
  iconBannerCls,
  iconBannerIconCls,
  iconBannerContentCls,
  iconBannerContentMainCls,
} from 'shared_components/messages/IconBanner.less';

type Props = {
  icon: string,
  translationPreffix: string,
};

/**
 * IconBannerMessage
 *
 * @param {Props} props
 * @returns {Node}
 */
export const IconBannerMessage = ({ icon, translationPreffix }: Props): Node => {
  const ICONS = { van: VanIcon };
  const IconComponent = ICONS[icon];
  const storeCode = useStoreCode();
  const currencySign =
    CONFIG_APP.i18n.currency.signs[storeCode && storeCode.toLowerCase()] ||
    CONFIG_APP.i18n.currency.signs.uk;

  return (
    <div className={iconBannerCls}>
      {IconComponent ? (
        <div className={iconBannerIconCls}>
          <IconComponent />
        </div>
      ) : null}
      <div className={iconBannerContentCls}>
        <MediaQuery minWidth={raScreenMd}>
          {(isLarge) => (
            <Fragment>
              <strong className={iconBannerContentMainCls}>
                <FormattedMessage id={`${translationPreffix}.line1`} values={{ currencySign }}>
                  {(text: string) => text}
                </FormattedMessage>
              </strong>
              <FormattedMessage
                id={`${translationPreffix}.${isLarge ? 'line2' : 'line2Truncated'}`}
                values={{
                  currencySign,
                  strong: (chunks) => <strong>{chunks}</strong>,
                  div: (chunks) => <div>{chunks}</div>,
                }}
              >
                {(text: string) => text}
              </FormattedMessage>
            </Fragment>
          )}
        </MediaQuery>
      </div>
    </div>
  );
};
