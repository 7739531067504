// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Heading, Paragraph } from '@riseart/common';
import { Overlay, OverlayHeader, OverlayBody, OverlayContainer } from '@riseart/layout';
import { useAclAccess } from '@riseart/fe-utils';
import { application as CONFIG_APP } from 'Config';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { CartDiscountForm } from 'shared_components/forms/cart/Discount';
import { CartGiftCardForm } from 'shared_components/forms/cart/GiftCard';
import { CartCreditSection } from 'shared_components/cart/sections/credit/Credit';
import { WithOffsetHeight } from 'shared_hocs/gui/withOffsetHeight';
import { selectAclRole } from 'shared_services/redux/selectors/auth';

import { cartActionsOverlayCls } from 'shared_components/cart/actions/overlay/Overlay.less';

type Props = {
  cartType: string,
  openedOverlay: ?string,
  storeCode: string,
  useCredit: boolean,
  customerCredit: number,
  handleOverlayOpen: Function,
  handleDiscountApply: Function,
  handleUseCredit: Function,
  handleGiftCardSubmit: Function,
};

/**
 * CartActionsOverlay
 */
export const CartActionsOverlay = ({
  cartType,
  openedOverlay,
  storeCode,
  useCredit = false,
  customerCredit,
  handleOverlayOpen,
  handleDiscountApply,
  handleUseCredit,
  handleGiftCardSubmit,
}: Props) => {
  const aclRole = useSelector(selectAclRole);
  const { checkAclAccess } = useAclAccess({
    role: aclRole,
    rolesHierarchy: CONFIG_APP.acl.rolesHierarchy,
  });

  if (!openedOverlay) {
    return null;
  }

  const CONFIG_HASH = LocalizedConfig.get('navigation.uri.hash.params');

  return (
    <WithOffsetHeight>
      {({ notificationsHeight }) => (
        <Overlay
          className={cartActionsOverlayCls}
          isVisible={!!openedOverlay}
          type="semitransparent"
          size="medium"
          onClose={handleOverlayOpen(null)}
          topOffset={notificationsHeight}
        >
          <OverlayContainer>
            <OverlayHeader border={false} />
            <OverlayBody>
              {openedOverlay === CONFIG_HASH.discount ? (
                <React.Fragment>
                  <Heading tag="h3" level="3">
                    <FormattedMessage id="components.cart.useDiscount">
                      {(label: string) => label}
                    </FormattedMessage>
                  </Heading>
                  <CartDiscountForm onSubmit={handleDiscountApply} />
                </React.Fragment>
              ) : null}
              {openedOverlay === CONFIG_HASH.credit ? (
                <CartCreditSection
                  cartType={cartType}
                  handleUseCredit={handleUseCredit}
                  redeemCardHandler={handleOverlayOpen(CONFIG_HASH.giftCard)}
                  useCredit={useCredit}
                  storeCode={storeCode}
                  customerCredit={customerCredit}
                  canAccessCredit={checkAclAccess(CONFIG_APP.acl.resourcePermissions.cart.credit)}
                  canAccessGiftCard={checkAclAccess(
                    CONFIG_APP.acl.resourcePermissions.cart.giftCard,
                  )}
                />
              ) : null}
              {openedOverlay === CONFIG_HASH.giftCard ? (
                <React.Fragment>
                  <Heading tag="h3" level="3">
                    <FormattedMessage id="components.cart.redeemGiftCard">
                      {(label: string) => label}
                    </FormattedMessage>
                  </Heading>
                  <Paragraph>
                    <FormattedMessage id="components.cart.redeemGiftDesc">
                      {(label: string) => label}
                    </FormattedMessage>
                  </Paragraph>
                  <CartGiftCardForm onSubmit={handleGiftCardSubmit} />
                </React.Fragment>
              ) : null}
            </OverlayBody>
          </OverlayContainer>
        </Overlay>
      )}
    </WithOffsetHeight>
  );
};
