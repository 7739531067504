// @flow

import { application as CONFIG_APP } from 'Config';
import { stores as ENUM_STORES, units as ENUM_UNITS } from 'Enum';
import { shippingBuy as SHIPPING_COUNTIRES } from 'Countries';

export const RegionalFormModel = {
  settings: {
    hideRequiredMark: true,
    layout: 'horizontal',
  },
  fields: [
    {
      tag: 'select',
      name: 'locale',
      options: (intl: Object): Array<Object> =>
        CONFIG_APP.i18n.locales.map(({ name }) => ({
          value: name,
          text: intl.formatMessage({ id: `locales.${name}` }),
        })),
      label: 'forms.regionalForm.localeSelectLabel',
    },
    {
      tag: 'select',
      name: 'store',
      options: (intl: Object): Array<Object> =>
        ENUM_STORES.map((currencyCode) => ({
          value: currencyCode,
          text: intl.formatMessage({ id: `stores.${currencyCode}` }),
        })),
      label: 'forms.regionalForm.currencySelectLabel',
    },
    {
      tag: 'select',
      name: 'country',
      options: (intl: Object): Array<Object> =>
        SHIPPING_COUNTIRES.map((countryCode) => ({
          value: countryCode,
          text: intl.formatMessage({ id: `countries.${countryCode}` }),
        })),
      label: 'forms.regionalForm.countrySelectLabel',
    },
    {
      tag: 'select',
      name: 'unitSystem',
      options: (intl: Object): Array<Object> =>
        Object.keys(ENUM_UNITS.system).map((key) => ({
          value: ENUM_UNITS.system[key],
          text: intl.formatMessage({ id: `forms.regionalForm.units.${key}` }),
        })),
      label: 'forms.regionalForm.unitsSelectLabel',
    },
  ],
};
